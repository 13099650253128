import React, { useEffect, useState } from "react";

import { CustomerCompetitorsViewModel } from "../../../../viewmodels/customers/CustomerCompetitorsViewModel";
import { CustomerCompetitorsHeaderArea } from "./CustomerCompetitorsHeaderArea";
import { CustomerCompetitorsTable } from "./CustomerCompetitorsTable";

export const CustomerCompetitors = (props) => {
  const {
    checkRequestError,
    currency,
    customerId,
    openActionModal,
    setErrorModalOpen,
    setOpen,
    setOpenActionModal,
    setShow,
    setSidePanelExtra,
    setOpenSidePanel,
  } = props;

  const {
    competitors,
    deleteProductCompetitorFromTable,
    getCustomerCompetitors,
    fetchAllRules,
    page,
    rules,
    updateCompetitorInParentDomains,
  } = CustomerCompetitorsViewModel(
    checkRequestError,
    customerId,
    setErrorModalOpen,
    setShow,
    setOpenSidePanel,
    setSidePanelExtra
  );

  useEffect(() => {
    getCustomerCompetitors(page, "");
    fetchAllRules();
  }, []);

  const handleSearch = (e) => {
    const term = e.target.value;
    if (term.length >= 3) {
      getCustomerCompetitors(page, term);
    }
  };

  return (
    <div className="mx-auto py-6 sm:px-6 lg:px-8">
      <CustomerCompetitorsHeaderArea />
      <CustomerCompetitorsTable
        checkRequestError={checkRequestError}
        competitors={competitors}
        currency={currency}
        customerId={customerId}
        deleteProductCompetitorFromTable={deleteProductCompetitorFromTable}
        fetchAllRules={fetchAllRules}
        openActionModal={openActionModal}
        rules={rules}
        setErrorModalOpen={setErrorModalOpen}
        setShow={setShow}
        setOpen={setOpen}
        setOpenActionModal={setOpenActionModal}
        setOpenSidePanel={setOpenSidePanel}
        setSidePanelExtra={setSidePanelExtra}
        updateCompetitorInParentDomains={updateCompetitorInParentDomains}
      />
    </div>
  );
};
