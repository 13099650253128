import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import { CustomersViewModel } from "../../viewmodels/customers/CustomersViewModel";

import { FunnelIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { Link } from "react-router-dom";

import ErrorModal from "../../components/ModalTemplates/ErrorModal";

const Customers = (props) => {
  const { checkRequestError, userId } = props;

  const { customers, errorModalOpen, getCustomers, page, setErrorModalOpen } =
    CustomersViewModel(checkRequestError, userId);

  useEffect(() => {
    document.title = "Customers";

    getCustomers(checkRequestError, page, "");
  }, []);

  const handleSearch = (e) => {
    const term = e.target.value;
    if (term.length >= 3) {
      getCustomers(checkRequestError, page, term);
    }
  };

  return (
    <main>
      <div className="py-6 sm:px-6 lg:px-8">
        {/* Start here */}
        <div className="px-4 py-6 sm:px-0">
          <div className="bg-white px-4 py-5 sm:px-12">
            <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
              <div className="ml-4 mt-2">
                {/* search starts */}
                <div className="mt-1 flex rounded-md shadow-sm">
                  <div className="relative flex items-stretch flex-grow focus-within:z-10">
                    <input
                      type="text"
                      name="searchCustomer"
                      id="searchCustomer"
                      className=" block w-full rounded-lg rounded-l-md pl-2 sm:text-md border border-gray-300 rounded-tr-none rounded-br-none"
                      placeholder="Search"
                      onChange={handleSearch}
                    />
                  </div>
                  <button
                    type="button"
                    className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none"
                  >
                    <MagnifyingGlassIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </button>
                </div>
                {/* search ends */}
              </div>
              <div className="ml-4 mt-2 flex-shrink-0">
                <button
                  type="button"
                  className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-400 hover:bg-indigo-700"
                >
                  <FunnelIcon
                    className="-ml-1 mr-2 h-5 w-5"
                    aria-hidden="true"
                  />
                  Filter
                </button>
              </div>
            </div>
          </div>

          {/* table starts */}
          <div className="bg-white mb-6 px-4 py-5 border-b border-gray-200 sm:px-12">
            <div className="flex flex-col pt-6">
              <div className="-my-2 overflow-x-visible sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-visible border-b border-gray-200 sm:rounded-lg  w-full">
                    <table className="w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Email
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Website
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Subscription
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          ></th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {customers.map((customer) => (
                          <tr key={customer.userId}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {customer.firstname} {customer.lastname}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {customer.email}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {customer.website}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {customer.subscription}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm">
                              <Link
                                type="button"
                                className={
                                  "relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-400 hover:bg-indigo-700"
                                }
                                to={"/customers/" + customer.userId}
                              >
                                View
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ErrorModal
        open={errorModalOpen.open}
        title={errorModalOpen.title}
        setOpen={setErrorModalOpen}
        subtitle={errorModalOpen.subtitle}
      />
    </main>
  );
};

const mapStateToProps = (state) => {
  return {
    currency: state.auth.currencyLabel,
    // jwt: state.auth.jwt,
    userId: state.auth.userId,
  };
};

export default connect(mapStateToProps)(Customers);
