import { useState } from "react";
import {
  fetchAllRulesApiCall,
  getCustomerCompetitorsApiCall,
} from "../../domain/networkCalls/networkCalls";

import { updateObjectInArray } from "../../shared/utility";

export function CustomerCompetitorsViewModel(
  checkRequestError,
  customerId,
  setErrorModalOpen,
  setShow,
  setOpenSidePanel
) {
  const [competitors, setCompetitors] = useState([]);
  const [page, setPage] = useState(1);
  const [rules, setRules] = useState([]);

  async function getCustomerCompetitors(page, search) {
    console.log("getCustomerCompetitors got called");
    const callResult = await getCustomerCompetitorsApiCall(
      customerId,
      page,
      search
    );

    if (callResult.success) {
      console.log("callresult:", callResult.data);
      setCompetitors(callResult.data.competitors);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Oops",
          subtitle: "We could not fetch the competitors, please try again.",
        });
      }
    }
  }

  const fetchAllRules = async function () {
    let callResult = await fetchAllRulesApiCall(customerId);

    if (callResult.success) {
      setRules(callResult.data.pricingRules);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
      }
    }
  };

  const updateCompetitorInParentDomains = (competitor, message) => {
    const currentCompetitors = [...competitors];

    const competitorIndex = competitors.findIndex(
      (comp) => comp.competitorId === competitor.competitorId
    );

    console.log("competitorIndex", competitorIndex);
    console.log("update_updateCompetitorInParentDomains", competitor);

    const payload = {
      index: competitorIndex,
      item: competitor,
    };
    const updatedCompetitors = updateObjectInArray(currentCompetitors, payload);

    console.log("updatedCompetitors", updatedCompetitors);

    setCompetitors(updatedCompetitors);

    setOpenSidePanel(false);
    setShow({ show: true, title: message });
  };

  function deleteProductCompetitorFromTable(competitorId) {
    console.log("competitorId", competitorId);
    const updatedCompetitors = competitors.filter(
      (competitor) => competitor.competitorId !== competitorId
    );

    setCompetitors(updatedCompetitors);
    setShow({ show: true, title: "Competitor deleted." });
  }

  return {
    competitors,
    deleteProductCompetitorFromTable,
    getCustomerCompetitors,
    fetchAllRules,
    page,
    rules,
    updateCompetitorInParentDomains,
  };
}
