import React, { useEffect, useState } from "react";

import { useForm } from "../../useForm";

import Input from "../../controls/Input";
import CustomSelect from "../../controls/CustomSelect";
import TooltipButton from "../../TooltipButton";

import CreatableSelect from "react-select/creatable";
import { makeApiCall } from "../../../shared/makeApiCall";

import "wc-spinners/dist/hollow-dots-spinner.js";

const initialFValues = {
  productId: "",
  url: "",
  name: "",
  brand: "",
  category: "",
  barcode: "",
  sku: "",
  previousCost: "",
  cost: "",
  apiProductId: "",
  isCustomMargin: 0,
  respectMinPlusOrMinus: 0,
  respectMinAmount: 0,
  respectMinCurrencyOrPercentage: 0,
  respectMaxPlusOrMinus: 0,
  respectMaxAmount: 0,
  respectMaxCurrencyOrPercentage: 0,
  isCustomDecimals: 0,
  preferenceEndWith: 0,
  smartPricePreference: 0,
  isCustomFieldToEdit: 0,
  fieldToEdit: 0,
  status: 3,
};

const fieldOptions = [
  { name: "Regular price", value: 0 },
  { name: "Sales price", value: 1 },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ProductDetailsForm(props) {
  const {
    brands,
    categories,
    checkRequestError,
    currency,
    customerId,
    fetchAllBrands,
    fetchAllCategories,
    product,
    updateProductInState,
    setErrorModalOpen,
  } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [submitError, setSubmitError] = useState(0);

  const updateProductFunc = async function (data) {
    setSubmitError(null);
    setDisableSubmitButton(true);
    const authData = {
      action: "updateProduct",
      customerId: customerId,
      data: data,
    };
    let callResult = await makeApiCall(authData);
    setDisableSubmitButton(false);
    if (callResult.success) {
      updateProductInState(callResult.data.product, callResult.data.message);

      if (callResult.data.refreshBrands) {
        fetchAllBrands();
      }

      if (callResult.data.refreshCategories) {
        fetchAllCategories();
      }
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setSubmitError(callResult.data.subtitle);
      }
      setErrorModalOpen({
        open: true,
        title: "Signed out.",
        subtitle: "Please login again.",
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      updateProductFunc(values);
    }
  };

  useEffect(() => {
    if (product != null) {
      setValues(product);
    }
  }, [product]);

  const convertToDefEventPara = (name, value) => {
    return {
      target: {
        name,
        value,
      },
    };
  };

  const handleBrandsMultiSelectChange = (newValue, actionMeta) => {
    if (actionMeta.action == "create-option") {
      newValue = {
        value: "new_create_option",
        label: newValue.value,
      };
    }
    handleInputChange(convertToDefEventPara("brandArray", newValue));
  };

  const handleCategoriesMultiSelectChange = (newValue, actionMeta) => {
    if (actionMeta.action == "create-option") {
      newValue = {
        value: "new_create_option",
        label: newValue.value,
      };
    }

    handleInputChange(convertToDefEventPara("categoryArray", newValue));
  };

  const changeProductStatus = async function (newStatus) {
    setDisableSubmitButton(true);
    const authData = {
      action: "changeProductStatus",
      customerId: customerId,
      productId: values.productId,
      status: newStatus,
    };
    let callResult = await makeApiCall(authData);

    setDisableSubmitButton(false);
    if (callResult.success) {
      const statusObj = { status: newStatus };
      updateProductInState(
        { ...product, ...statusObj },
        callResult.data.message
      );
    } else {
      console.log("inthe errrrrrror");
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Oops",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
      }
      setErrorModalOpen({
        open: true,
        title: "Signed out.",
        subtitle: "Please login again.",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="justify-between px-4">
        <div className="divide-y divide-gray-500">
          <div className="pt-6 pb-5 flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div className="mt-2">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Product Details
              </h3>
            </div>
            <div className="mt-2 flex-shrink-0">
              <button
                type="button"
                onClick={() => changeProductStatus(values.status > 0 ? 0 : 3)}
                className={classNames(
                  values.status > 0
                    ? "bg-green-600 hover:bg-green-700"
                    : "bg-red-600 hover:bg-red-700",
                  "inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white items-center"
                )}
                disabled={disableSubmitButton}
              >
                {disableSubmitButton ? (
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : values.status > 0 ? (
                  "Activate product"
                ) : (
                  "Archive product"
                )}
              </button>
            </div>
          </div>

          {/* here */}
          <div className="space-y-3 pt-6 pb-5">
            <div className="max-w-md">
              <Input
                label="Name"
                name="name"
                onChange={handleInputChange}
                value={values.name}
                labelOn={true}
                explanation="Name of your product"
              />
            </div>
            <div className="max-w-md">
              <Input
                label="Url"
                name="url"
                onChange={handleInputChange}
                value={values.url}
                labelOn={true}
                explanation="The url/link to the product on your website"
              />
            </div>
            <div className="max-w-md">
              <div className="flex space-x-2">
                <label
                  htmlFor="brand"
                  className="block text-sm font-medium text-gray-900"
                >
                  Brand
                </label>
                <TooltipButton explanation="Brand of your product" />
              </div>
              <div className="mt-1">
                <CreatableSelect
                  name="brand"
                  options={brands}
                  onChange={handleBrandsMultiSelectChange}
                  defaultValue={product ? product.brandArray : []}
                  placeholder=""
                  noOptionsMessage={() => "Type to add new brand"}
                />
              </div>
            </div>
            <div className="max-w-md">
              <div className="flex space-x-2">
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-900"
                >
                  Category
                </label>
                <TooltipButton explanation="Category of your product" />
              </div>
              <div className="sm:col-span-1 mt-1">
                <CreatableSelect
                  name="category"
                  options={categories}
                  onChange={handleCategoriesMultiSelectChange}
                  defaultValue={product ? product.categoryArray : []}
                  placeholder=""
                  noOptionsMessage={() => "Type to add new category"}
                />
              </div>
            </div>
            <div className="max-w-md">
              <Input
                label="Barcode"
                name="barcode"
                onChange={handleInputChange}
                value={values.barcode}
                labelOn={true}
                explanation="Barcode of your product has to be unique"
              />
            </div>
            <div className="max-w-md">
              <Input
                label="SKU"
                name="sku"
                onChange={handleInputChange}
                value={values.sku}
                labelOn={true}
                explanation="Sku of your product, has to be unique"
              />
            </div>
            <div className="max-w-md">
              <Input
                label="Cost"
                name="cost"
                onChange={handleInputChange}
                value={values.cost}
                labelOn={true}
                explanation="Cost of the product to you"
              />
            </div>
            <div className="max-w-md">
              <Input
                label="Product Id"
                name="apiProductId"
                onChange={handleInputChange}
                value={values.apiProductId}
                labelOn={true}
                explanation="The product's id in your system, contact support if you have questions"
              />
            </div>
            <div className="sm:col-span-2">
              <div className="grid grid-cols-6 pt-2 pr-2">
                <div className="sm:col-span-6">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Smart Price configurations
                  </h3>
                </div>
              </div>
              <div>
                <div className="flex space-x-2">
                  <label
                    htmlFor="rules"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Rules
                  </label>
                  <TooltipButton explanation="Choose a rule below that you have already created" />
                </div>
                <div className="max-w-md gap-4">
                  <select
                    id="smartPricePreference"
                    name="smartPricePreference"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                    value={values.smartPricePreference}
                    onChange={handleInputChange}
                  >
                    <option value={0}>Minimum</option>
                    <option value={1}>Average</option>
                    <option value={2}>Maximum</option>
                  </select>
                </div>
              </div>
              <fieldset className="mt-4">
                <legend className="sr-only">Use custom margin?</legend>
                <div className="relative flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="isCustomMargin"
                      aria-describedby="comments-description"
                      name="isCustomMargin"
                      type="checkbox"
                      className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      checked={values.isCustomMargin === "1" ? true : false}
                      onChange={(e) => {
                        handleInputChange(
                          convertToDefEventPara(
                            e.target.name,
                            e.target.checked ? "1" : "0"
                          )
                        );
                      }}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="comments"
                      className="font-medium text-gray-700"
                    >
                      Use custom margin?
                    </label>
                    <span id="comments-description" className="text-gray-500">
                      <span className="sr-only">Use custom margin?</span> This
                      will overwrite your default margin...
                    </span>
                  </div>
                </div>
              </fieldset>
              {values.isCustomMargin === "1" ? (
                <div className="grid grid-cols-4 p-2">
                  <div className="sm:col-span-4">
                    <h3 className="text-lg leading-4 font-medium text-gray-900">
                      Margin/markup
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      Choose what the dynamic price should reflect
                    </p>
                  </div>
                  <div className="sm:col-span-1">Minimum: </div>
                  <div className="sm:col-span-1 ml-0.5">
                    <select
                      id="respectMinPlusOrMinus"
                      name="respectMinPlusOrMinus"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                      value={values.respectMinPlusOrMinus}
                      onChange={handleInputChange}
                    >
                      <option value={0}>Cost +</option>
                      <option value={1}>Absolute value</option>
                    </select>
                  </div>
                  <div className="sm:col-span-1 ml-0.5">
                    <Input
                      label="Respect Minimum amount"
                      name="respectMinAmount"
                      onChange={handleInputChange}
                      value={values.respectMinAmount}
                      type="number"
                      step="0.01"
                      min="0"
                    />
                  </div>
                  <div className="sm:col-span-1 ml-0.5">
                    <select
                      id="respectMinCurrencyOrPercentage"
                      name="respectMinCurrencyOrPercentage"
                      value={values.respectMinCurrencyOrPercentage}
                      onChange={handleInputChange}
                      className={classNames(
                        values.respectMinPlusOrMinus === "1"
                          ? "hidden"
                          : "block w-full rounded-lg rounded-l-md pl-2 sm:text-md border border-gray-300 py-2 px-2 mt-1"
                      )}
                    >
                      <option value={0}>{currency}</option>
                      <option value={1}>%</option>
                    </select>
                  </div>
                  <div className="sm:col-span-1 mt-3">Maximum: </div>
                  <div className="sm:col-span-1 ml-0.5 mt-3">
                    <select
                      id="respectMaxPlusOrMinus"
                      name="respectMaxPlusOrMinus"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                      value={values.respectMaxPlusOrMinus}
                      onChange={handleInputChange}
                    >
                      <option value={0}>Cost +</option>
                      <option value={1}>Absolute value</option>
                      <option value={2}>Not set</option>
                    </select>
                  </div>
                  <div
                    className={classNames(
                      values.respectMaxPlusOrMinus === "2"
                        ? "hidden"
                        : "sm:col-span-1 ml-0.5 mt-3"
                    )}
                  >
                    <Input
                      label="Respect Maximum amount"
                      name="respectMaxAmount"
                      onChange={handleInputChange}
                      value={values.respectMaxAmount}
                      type="number"
                      step="0.01"
                      min="0"
                    />
                  </div>
                  <div
                    className={classNames(
                      values.respectMaxPlusOrMinus === "1" ||
                        values.respectMaxPlusOrMinus === "2"
                        ? "hidden"
                        : "sm:col-span-1 ml-0.5 mt-3"
                    )}
                  >
                    <select
                      id="respectMaxCurrencyOrPercentage"
                      name="respectMaxCurrencyOrPercentage"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                      value={values.respectMaxCurrencyOrPercentage}
                      onChange={handleInputChange}
                    >
                      <option value={0}>{currency}</option>
                      <option value={1}>%</option>
                    </select>
                  </div>
                </div>
              ) : null}
              <fieldset className="sm:col-span-6 mt-4">
                <legend className="sr-only">Use custom ending?</legend>
                <div className="relative flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="isCustomDecimals"
                      aria-describedby="comments-description"
                      name="isCustomDecimals"
                      type="checkbox"
                      className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      checked={values.isCustomDecimals === "1" ? true : false}
                      onChange={(e) => {
                        handleInputChange(
                          convertToDefEventPara(
                            e.target.name,
                            e.target.checked ? "1" : "0"
                          )
                        );
                      }}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="comments"
                      className="font-medium text-gray-700"
                    >
                      Use custom decimal endings?
                    </label>
                    <span id="comments-description" className="text-gray-500">
                      <span className="sr-only">
                        Use custom decimal endings?
                      </span>{" "}
                      This will overwrite your default decimal endings...
                    </span>
                  </div>
                </div>
              </fieldset>
              {values.isCustomDecimals === "1" ? (
                <div className="mt-6 grid grid-cols-12 gap-x-4">
                  <div className="sm:col-span-12">
                    <label>Always end decimal prices with</label>
                  </div>

                  <div className="sm:col-span-2">
                    <select
                      id="preferenceEndWith"
                      name="preferenceEndWith"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                      value={values.preferenceEndWith}
                      onChange={handleInputChange}
                    >
                      <option value={0}>00</option>
                      <option value={1}>49</option>
                      <option value={2}>50</option>
                      <option value={3}>90</option>
                      <option value={4}>95</option>
                      <option value={5}>99</option>
                    </select>
                  </div>
                </div>
              ) : null}

              <fieldset className="sm:col-span-6 mt-4">
                <legend className="sr-only">Use custom ending?</legend>
                <div className="relative flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="isCustomFieldToEdit"
                      aria-describedby="comments-description"
                      name="isCustomFieldToEdit"
                      type="checkbox"
                      className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      checked={
                        values.isCustomFieldToEdit === "1" ? true : false
                      }
                      onChange={(e) => {
                        handleInputChange(
                          convertToDefEventPara(
                            e.target.name,
                            e.target.checked ? "1" : "0"
                          )
                        );
                      }}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="comments"
                      className="font-medium text-gray-700"
                    >
                      Use custom field to edit?
                    </label>
                    <span id="comments-description" className="text-gray-500">
                      <span className="sr-only">Use custom field to edit?</span>{" "}
                      This will overwrite your default field to edit...
                    </span>
                  </div>
                </div>
              </fieldset>
              {values.isCustomFieldToEdit === "1" ? (
                <div className="mt-6 gap-x-4 max-w-md">
                  <CustomSelect
                    label="Price field to edit"
                    name="fieldToEdit"
                    onChange={handleInputChange}
                    value={values.fieldToEdit}
                    labelOn={true}
                    options={fieldOptions}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                    error={errors.fieldToEdit}
                  />
                </div>
              ) : null}
            </div>
            {submitError ? (
              <p className="text-md text-red-500">{submitError}</p>
            ) : null}
            <div className="sm:flex-no-wrap">
              <div className="my-auto flex items-center">
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 items-center"
                  disabled={disableSubmitButton}
                >
                  {disableSubmitButton ? (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
