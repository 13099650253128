import { makeApiCall } from "../../shared/makeApiCall";

export const getCustomersApiCall = async function (page, search) {
  const authData = {
    action: "fetchAllCustomers",
    search: search,
    page: page,
  };
  return await makeApiCall(authData);
};

export const getCustomersDetailsApiCall = async function (customerId) {
  const authData = {
    action: "fetchCustomerDetailsById",
    customerId: customerId,
  };
  return await makeApiCall(authData);
};

export const updateCustomerDetailsApiCall = async function (data) {
  const authData = {
    action: "updateCustomerById",
    data: data,
  };
  return await makeApiCall(authData);
};

export const getCustomerProductsApiCall = async function (
  customerId,
  page,
  search,
  status
) {
  const authData = {
    action: "fetchCustomerProducts",
    customerId: customerId,
    pageNumber: page,
    search: search,
    status: status,
  };
  return await makeApiCall(authData);
};

export const addCompetitorApiCall = async function (customerId, data) {
  console.log("===addCompetitorApiCall got called");
  const authData = {
    action: "addCompetitor",
    customerId: customerId,
    data: data,
  };
  console.log("===addCompetitorApiCall authData: ", authData);
  return await makeApiCall(authData);
};

export const updateCompetitorForProductApiCall = async function (
  customerId,
  data
) {
  const authData = {
    action: "updateCompetitorForProduct",
    customerId: customerId,
    data: data,
  };
  return await makeApiCall(authData);
};

export const updateCompetitorParentDomainApiCall = async function (
  customerId,
  data
) {
  const authData = {
    action: "updateCompetitorParentDomain",
    customerId: customerId,
    data: data,
  };
  return await makeApiCall(authData);
};

export const fetchAllBrandsApiCall = async function (customerId) {
  const authData = {
    action: "fetchAllBrandsForDropdown",
    customerId: customerId,
  };
  return await makeApiCall(authData);
};

export const fetchAllCategoriesApiCall = async function (customerId) {
  const authData = {
    action: "fetchAllCategoriesForDropdown",
    customerId: customerId,
  };
  return await makeApiCall(authData);
};

export const fetchAllRulesApiCall = async function (customerId) {
  const authData = {
    action: "fetchAllPricingRulesByUserId",
    customerId: customerId,
  };
  return await makeApiCall(authData);
};

export const queueAuditForProductApiCall = async function (
  productId,
  isUpdateApi,
  customerId
) {
  const authData = {
    action: "addProductToQueue",
    productId: productId,
    isUpdateApi: isUpdateApi,
    customerId: customerId,
  };
  return await makeApiCall(authData);
};

export const getAllProductsInPryceHubApiCall = async function (
  pageNumber,
  search,
  status
) {
  const authData = {
    action: "fetchAllProductsInPryceHub",
    pageNumber: pageNumber,
    search: search,
    status: status,
  };
  return await makeApiCall(authData);
};

export const fetchProductByIdApiCall = async function (productId) {
  const authData = {
    action: "fetchProductById",
    productId: productId,
  };
  return await makeApiCall(authData);
};

export const deleteAssignedCompetitorApiCall = async function (
  customerId,
  urlId
) {
  const authData = {
    action: "deleteAssignedCompetitor",
    customerId: customerId,
    urlId: urlId,
  };
  return await makeApiCall(authData);
};

export const deleteAssignedCompetitorAtCustomerLevelApiCall = async function (
  customerId,
  competitorId
) {
  const authData = {
    action: "deleteAssignedCompetitorAtCustomerLevel",
    customerId: customerId,
    competitorId: competitorId,
  };
  return await makeApiCall(authData);
};

export const getCustomerCompetitorsApiCall = async function (
  customerId,
  page,
  search
) {
  const authData = {
    action: "fetchCompetitorsByCustomerId",
    customerId: customerId,
    pageNumber: page,
    search: search,
  };
  return await makeApiCall(authData);
};

export const getProductCompetitorsApiCall = async function (
  productId,
  customerId,
  page,
  search
) {
  const authData = {
    action: "getProductCompetitorsByProductId",
    productId: productId,
    customerId: customerId,
    pageNumber: page,
    search: search,
  };
  return await makeApiCall(authData);
};

export const fetchProductScrapesApiCall = async function (
  customerId,
  productId
) {
  const authData = {
    action: "fetchProductScrapes",
    customerId: customerId,
    productId: productId,
  };
  return await makeApiCall(authData);
};

export const scrapeSingleProductApiCall = async function (
  customerId,
  productId,
  isUpdateApi
) {
  const authData = {
    action: "scrapeSingleProduct",
    customerId: customerId,
    productId: productId,
    isUpdateApi: isUpdateApi,
  };
  return await makeApiCall(authData);
};

export const getAllDomainsInPryceHubApiCall = async function (
  pageNumber,
  search,
  status
) {
  const authData = {
    action: "getAllDomainsInPryceHub",
    pageNumber: pageNumber,
    search: search,
    status: status,
  };
  return await makeApiCall(authData);
};

export const scrapeUrlApiCall = async function (url) {
  const authData = {
    action: "scrapeUrlTest",
    url: url,
  };
  return await makeApiCall(authData);
};
