import { useState } from "react";
import { getAllDomainsInPryceHubApiCall } from "../../domain/networkCalls/networkCalls";

export function AllDomainsViewModel(checkRequestError, setErrorModalOpen) {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [domains, setDomains] = useState({ domains: [], total: 0 });

  async function getAllDomainsInPryceHub(page, search, status) {
    const callResult = await getAllDomainsInPryceHubApiCall(
      page,
      search,
      status
    );

    setLoading(false);
    if (callResult.success) {
      console.log("callresult:", callResult.data);
      setDomains({
        domains: callResult.data.domains.domains,
        total: callResult.data.domains.total,
      });
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Oops",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
      }
    }
  }

  return {
    getAllDomainsInPryceHub,
    loading,
    page,
    domains,
  };
}
