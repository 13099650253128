import { ClockIcon } from "@heroicons/react/24/solid";

import { PlusIcon } from "@heroicons/react/24/outline";

import { formatDate } from "../../../../shared/utility";
import TooltipText from "../../../../components/TooltipText";

import { ProductQuickActions } from "./subComponents/ProductQuickActions";
import { ProductTabCompetitorQuickActions } from "./subComponents/ProductTabCompetitorQuickActions";

export const ProductsTable = (props) => {
  const {
    addNewCompetitorToProduct,
    archivedProductSuccessfully,
    brands,
    categories,
    checkRequestError,
    currency,
    customerId,
    deleteProductCompetitorFromState,
    fetchAllBrands,
    fetchAllCategories,
    fetchAllRules,
    loading,
    openActionModal,
    products,
    queueAuditForProduct,
    refreshProductsList,
    rules,
    setEmptyModal,
    setErrorModalOpen,
    setNewCompetitorsForProduct,
    setOpenActionModal,
    setOpenSidePanel,
    setShow,
    setSidePanelExtra,
    updateProduct,
    updateSingleProductCompetitor,
  } = props;

  console.log("ProductsTable=rules", rules);

  return (
    <>
      {/* table starts */}
      <div className="px-4 py-6 sm:px-0 rounded-xl">
        {loading && products.productList.length == 0 ? (
          <>
            <div className="border h-40 shadow rounded-md p-4 w-full mx-auto">
              <div className="animate-pulse flex space-x-4"></div>
            </div>
            <div className="border h-40 mt-5 shadow rounded-md p-4 w-full mx-auto">
              <div className="animate-pulse flex space-x-4"></div>
            </div>
            <div className="border h-40 mt-5 shadow rounded-md p-4 w-full mx-auto">
              <div className="animate-pulse flex space-x-4"></div>
            </div>
            <div className="border h-40 mt-5 shadow rounded-md p-4 w-full mx-auto">
              <div className="animate-pulse flex space-x-4"></div>
            </div>
          </>
        ) : products.productList.length > 0 ? (
          products.productList.map((product, productIndex) => (
            <div
              key={product.productId}
              className="bg-white mb-6 px-4 py-5 border-b border-gray-200 sm:px-12"
            >
              <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                <div className="ml-4 mt-4">
                  <h3 className="text-2xl leading-6 font-medium text-gray-900">
                    {product.name}
                  </h3>
                  <div className="mt-3 flex">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Dynamic Price: {product.dynamicPrice}
                    </h3>
                  </div>{" "}
                  <div className="mt-1 flex">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Last audited:{" "}
                      <font className="text-gray-700">
                        {formatDate(product.addedDate)}
                      </font>
                    </h3>
                  </div>
                </div>
                <div className="ml-4 mt-4 flex-shrink-0">
                  <ProductQuickActions
                    addNewCompetitorToProduct={addNewCompetitorToProduct}
                    archivedProductSuccessfully={archivedProductSuccessfully}
                    brands={brands}
                    categories={categories}
                    checkRequestError={checkRequestError}
                    currency={currency}
                    customerId={customerId}
                    fetchAllBrands={fetchAllBrands}
                    fetchAllCategories={fetchAllCategories}
                    openActionModal={openActionModal}
                    product={product}
                    queueAuditForProduct={queueAuditForProduct}
                    refreshProductsList={refreshProductsList}
                    setNewCompetitorsForProduct={setNewCompetitorsForProduct}
                    setOpenActionModal={setOpenActionModal}
                    setOpenSidePanel={setOpenSidePanel}
                    setShow={setShow}
                    setSidePanelExtra={setSidePanelExtra}
                    updateProduct={updateProduct}
                  />
                </div>
              </div>
              {/* table starts */}
              <div className="flex flex-col pt-6">
                <div className="-my-2 overflow-x-visible sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-visible border-b border-gray-200 sm:rounded-lg  w-full">
                      <table className="w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50 break-words">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              style={{ width: "25%" }}
                            >
                              Competitor
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              style={{ width: "10%" }}
                            >
                              Price
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              style={{ width: "10%" }}
                            >
                              Change
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              style={{ width: "7.5%" }}
                            >
                              Stock
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              style={{ width: "15%" }}
                            >
                              Pricing Rule
                            </th>
                            <th scope="col" className="relative px-6 py-3">
                              <span className="sr-only">Edit</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {product.competitors.map((competitor) => (
                            <tr key={competitor.urlId}>
                              <td
                                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                                style={{ width: "25%" }}
                              >
                                <a
                                  href={competitor.competitorUrl}
                                  target="_blank"
                                >
                                  {competitor.parentDomain}
                                </a>
                              </td>

                              <td
                                colSpan="3"
                                className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center"
                                style={{
                                  display:
                                    competitor.domainStatus === "0"
                                      ? "table-cell"
                                      : "none",
                                  width: "55%",
                                }}
                              >
                                Domain is being configured, it will be audited
                                automatically once configured.
                              </td>

                              <td
                                className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                colSpan="1"
                                style={{
                                  display:
                                    competitor.domainStatus === "0"
                                      ? "none"
                                      : "table-cell",
                                  width: "10%",
                                }}
                              >
                                {competitor.scrapedPrice === null ? (
                                  competitor.scrapeError ? (
                                    competitor.scrapeError
                                  ) : (
                                    <ClockIcon
                                      className="mt-2 h-6 w-6 text-indigo-700"
                                      aria-hidden="true"
                                    />
                                  )
                                ) : (
                                  competitor.scrapedPrice
                                )}
                              </td>
                              <td
                                className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                colSpan="1"
                                style={{
                                  display:
                                    competitor.domainStatus === "0"
                                      ? "none"
                                      : "table-cell",
                                  width: "10%",
                                }}
                              >
                                {competitor.compare === null ? (
                                  <ClockIcon
                                    className="mt-2 h-6 w-6 text-indigo-700"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  competitor.compare + "%"
                                )}
                              </td>
                              <td
                                className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                colSpan="1"
                                style={{
                                  display:
                                    competitor.domainStatus === "0"
                                      ? "none"
                                      : "table-cell",
                                  width: "7.5%",
                                }}
                              >
                                {competitor.stock === null ? (
                                  <ClockIcon
                                    className="mt-2 h-6 w-6 text-indigo-700"
                                    aria-hidden="true"
                                  />
                                ) : competitor.stock === "1" ? (
                                  "In stock"
                                ) : (
                                  "Out of stock"
                                )}
                              </td>

                              <td
                                className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                colSpan="1"
                                style={{ width: "15%" }}
                              >
                                {competitor.pr_product_ruleId > 0 ? (
                                  <TooltipText
                                    explanation={
                                      competitor.prod_rule_explanation
                                    }
                                    text={competitor.pr_product_name}
                                  />
                                ) : competitor.pr_competitor_ruleId > 0 ? (
                                  <TooltipText
                                    explanation={
                                      competitor.comp_rule_explanation
                                    }
                                    text={competitor.pr_competitor_name}
                                  />
                                ) : (
                                  <font className="text-red-500 font-medium">
                                    Pricing Rule not yet set
                                  </font>
                                )}
                              </td>
                              <td
                                className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                                style={{ width: "10%" }}
                              >
                                <div className="ml-2 h-7 flex items-center">
                                  <ProductTabCompetitorQuickActions
                                    checkRequestError={checkRequestError}
                                    competitor={competitor}
                                    currency={currency}
                                    customerId={customerId}
                                    deleteProductCompetitorFromState={
                                      deleteProductCompetitorFromState
                                    }
                                    fetchAllRules={fetchAllRules}
                                    openActionModal={openActionModal}
                                    product={product}
                                    productIndex={productIndex}
                                    rules={rules}
                                    setEmptyModal={setEmptyModal}
                                    setErrorModalOpen={setErrorModalOpen}
                                    setOpenActionModal={setOpenActionModal}
                                    setOpenSidePanel={setOpenSidePanel}
                                    setShow={setShow}
                                    setSidePanelExtra={setSidePanelExtra}
                                    updateSingleProductCompetitor={
                                      updateSingleProductCompetitor
                                    }
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
              />
            </svg>
            <h3 className="mt-2 text-sm font-medium text-gray-900">
              No products
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Get started by adding a new product
            </p>
            <div className="mt-6">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
              >
                <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Add product
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
