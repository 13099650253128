import React, { Fragment } from "react";

import { Menu, Transition } from "@headlessui/react";
import { ClockIcon } from "@heroicons/react/solid";

// import AssignCompetitorForm from "../../../../components/forms/AssignCompetitorForm";
// import AssignPricingRuleToCompetitorForm from "../../../components/forms/AssignPricingRuleToCompetitorForm";
// import ReportUrl from "../../../components/ModalTemplates/ReportUrl";

import { formatDate } from "../../../../shared/utility";
// import ImportCompetitorsModal from "../../../components/ModalTemplates/ImportCompetitorsModal";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
// import DeleteProductTemplate from "../../../components/ModalTemplates/DeleteProductTemplate";
import TooltipText from "../../../../components/TooltipText";
import AssignCompetitorForm from "../../../../components/forms/product/AssignCompetitorForm";
import { sourceProductsPage } from "../../../../constants";
import { ProductCompetitorsQuickActions } from "./subComponents/ProductCompetitorsQuickActions";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ProductCompetitors(props) {
  const {
    addNewCompetitorToProduct,
    checkRequestError,
    competitors,
    currency,
    customerId,
    deleteAssignedCompetitor,
    fetchAllRules,
    openActionModal,
    product,
    rules,
    setEmptyModal,
    setErrorModalOpen,
    setOpenActionModal,
    setOpenSidePanel,
    setShow,
    setSidePanelExtra,
    updateSingleProductCompetitor,
  } = props;

  // console.log("competitors", competitors);
  return (
    <div className="justify-between px-4">
      <div className="divide-y divide-gray-500">
        <div className="flex items-center justify-between flex-wrap sm:flex-nowrap space-y-3 pt-6 pb-5">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Competitors
          </h3>

          <div className="sm:flex-no-wrap px-4 sm:px-12">
            <button
              type="submit"
              className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-400 hover:bg-indigo-700 items-center"
              // onClick={() => {
              //   setOpenActionModal({
              //     open: true,
              //     setOpen: setOpenActionModal,
              //     childComponent: (
              //       <ImportCompetitorsModal
              //         open={openActionModal}
              //         productId={product.productId}
              //         setNewCompetitorsForProduct={setNewCompetitorsForProduct}
              //         setOpenActionModal={setOpenActionModal}
              //         setShow={setShow}
              //         userId={userId}
              //       />
              //     ),
              //   });
              // }}
            >
              Import competitors
            </button>
            <button
              type="submit"
              className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 items-center"
              onClick={() => {
                setSidePanelExtra({
                  childComponent: (
                    <AssignCompetitorForm
                      addNewCompetitorToProduct={addNewCompetitorToProduct}
                      checkRequestError={checkRequestError}
                      competitor={{
                        productId: product.productId,
                      }}
                      customerId={customerId}
                      isEdit={false}
                      productIndex={0}
                      setOpen={setOpenSidePanel}
                      source={sourceProductsPage}
                      subtitle="Fill in the information below to add a competitor."
                      title={"Add new competitor to " + product.name}
                      updateSingleProductCompetitor={
                        updateSingleProductCompetitor
                      }
                    />
                  ),
                });
                setOpenSidePanel(true);
              }}
            >
              Add competitor
            </button>
          </div>
        </div>
        <div className="space-y-3 pt-6 pb-5">
          <div className="">
            <div className="">
              <div className="align-middle inline-block w-full">
                <div className="shadow sm:rounded-lg">
                  <table className="table-auto w-full">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          width="20%"
                        >
                          Competitor
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          width="20%"
                        >
                          Price
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          width="20%"
                        >
                          Last updated
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          width="20%"
                        >
                          Pricing rule
                        </th>
                        <th scope="col" className="relative px-6 py-3"></th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {competitors.map((competitor) => (
                        <tr key={competitor.urlId}>
                          <td
                            className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400"
                            width="20%"
                          >
                            <a href={competitor.competitorUrl} target="_blank">
                              {competitor.parentDomain}
                            </a>
                          </td>

                          <td
                            colSpan="2"
                            className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center"
                            style={{
                              display:
                                competitor.domainStatus === "0"
                                  ? "table-cell"
                                  : "none",
                            }}
                          >
                            Domain is being configured, it will be audited
                            automatically once configured.
                          </td>

                          <td
                            className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400"
                            width="20%"
                            style={{
                              display:
                                competitor.domainStatus === "0"
                                  ? "none"
                                  : "table-cell",
                            }}
                          >
                            {competitor.price === null ? (
                              <ClockIcon
                                className="mt-2 h-6 w-6 text-indigo-700"
                                aria-hidden="true"
                              />
                            ) : (
                              competitor.price
                            )}
                          </td>
                          <td
                            className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400"
                            width="20%"
                            style={{
                              display:
                                competitor.domainStatus === "0"
                                  ? "none"
                                  : "table-cell",
                            }}
                          >
                            {competitor.scrapeId > 0
                              ? formatDate(competitor.lastUpdated)
                              : "Will update in a few hours"}
                          </td>
                          <td
                            className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400"
                            width="20%"
                          >
                            {competitor.pr_product_ruleId > 0 ? (
                              <TooltipText
                                explanation={competitor.prod_rule_explanation}
                                text={competitor.pr_product_name}
                              />
                            ) : competitor.pr_competitor_ruleId > 0 ? (
                              <TooltipText
                                explanation={competitor.comp_rule_explanation}
                                text={competitor.pr_competitor_name}
                              />
                            ) : (
                              <font className="text-red-500 font-medium">
                                Pricing Rule not yet set
                              </font>
                            )}
                          </td>
                          <td
                            className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                            width="20%"
                          >
                            <div className="ml-2 h-7 flex items-center">
                              <ProductCompetitorsQuickActions
                                checkRequestError={checkRequestError}
                                competitor={competitor}
                                currency={currency}
                                customerId={customerId}
                                deleteAssignedCompetitor={
                                  deleteAssignedCompetitor
                                }
                                fetchAllRules={fetchAllRules}
                                openActionModal={openActionModal}
                                product={product}
                                productIndex="0"
                                rules={rules}
                                setEmptyModal={setEmptyModal}
                                setErrorModalOpen={setErrorModalOpen}
                                setOpenActionModal={setOpenActionModal}
                                setOpenSidePanel={setOpenSidePanel}
                                setShow={setShow}
                                setSidePanelExtra={setSidePanelExtra}
                                updateSingleProductCompetitor={
                                  updateSingleProductCompetitor
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
