import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";

import { ChevronDownIcon } from "@heroicons/react/24/solid";

import ViewScrapeDetails from "../../../../../components/Templates/ViewScrapeDetails";

import ReportUrl from "../../../../../components/ModalTemplates/ReportUrl";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const ProductScrapesQuickActions = (props) => {
  const {
    checkRequestError,
    customerId,
    productId,
    scrape,
    setEmptyModal,
    setOpenSidePanel,
    setShow,
    setSidePanelExtra,
  } = props;

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  return (
    <>
      <span className="relative inline-flex shadow-sm rounded-md">
        <button
          className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
          onClick={() => {
            setSidePanelExtra({
              childComponent: (
                <ViewScrapeDetails
                  checkRequestError={checkRequestError}
                  productId={productId}
                  scrape={scrape}
                  setOpen={setOpenSidePanel}
                  subtitle=""
                  customerId={customerId}
                  title={""}
                />
              ),
              size: "7xl",
            });
            setOpenSidePanel(true);
          }}
        >
          View Audit
        </button>
        <Menu as="span" className="-ml-px relative block">
          <Menu.Button className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none">
            <span className="sr-only">Open options</span>
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="cursor-pointer origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-900">
              <div className="py-1 z-150 text-left">
                <Menu.Item>
                  {({ active }) => (
                    <a
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm cursor-pointer"
                      )}
                      onClick={() => {
                        setEmptyModal({
                          childComponent: (
                            <ReportUrl
                              action="reportAudit"
                              checkRequestError={checkRequestError}
                              customerId={customerId}
                              explanation="Report this audit if you think its not working properly, please explain
                              the issue in a few sentences below for us."
                              itemId={scrape.id}
                              setOpen={setEmptyModal}
                              setShow={setShow}
                              title="Report audit"
                            />
                          ),
                          open: true,
                        });
                      }}
                    >
                      Report Url
                    </a>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </span>
    </>
  );
};
