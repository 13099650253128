import React, { Fragment } from "react";
import { connect } from "react-redux";
import { useLocation, Navigate, Route, Routes } from "react-router-dom";

import Login from "../views/auth/Login";

import * as actions from "../store/actions/index";

const Auth = (props) => {
  return (
    <div className="min-h-screen">
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return { onLogout: () => dispatch(actions.logout()) };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
