import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import { useForm } from "../../components/useForm";

import Input from "../../components/controls/Input";
import { scrapeUrlApiCall } from "../../domain/networkCalls/networkCalls";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const initialFValues = {
  url: "",
};

const RunScrapeTest = (props) => {
  const { checkRequestError, userId } = props;

  const [dataLoaded, setDataLoaded] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [scrapeResult, setScrapeResult] = useState({ scrapeArray: [] });
  const [submitError, setSubmitError] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  async function scrapeUrl(url) {
    setDataLoaded(false);
    setDisableSubmitButton(true);
    const callResult = await scrapeUrlApiCall(url);

    if (callResult.success) {
      console.log("scrapeUrl success");
      setScrapeResult(callResult.data.scrapeData);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        console.log("error oggy");
        setSubmitError(callResult.data.subtitle);
      }
    }
    setDataLoaded(true);
    setDisableSubmitButton(false);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      scrapeUrl(values.url);
    }
  };

  useEffect(() => {
    document.title = "RunScrapeTest";
  }, []);

  return (
    <div className="bg-gray-50">
      <header className="bg-white shadow">
        <div className="max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900">
            Run a test scrape
          </h1>
        </div>
      </header>
      <main>
        <div className="py-6 sm:px-6 lg:px-8">
          <form onSubmit={handleSubmit}>
            <div className="justify-between px-4">
              <div className="">
                {/* here */}
                <div className="grid grid-cols-2 gap-4 pt-6 pb-5">
                  <div className="sm:col-span-2 max-w-md">
                    <Input
                      label="Put url to test below"
                      name="url"
                      onChange={handleInputChange}
                      value={values.website}
                      labelOn={true}
                    />
                  </div>

                  {submitError ? (
                    <p className="text-md text-red-500">{submitError}</p>
                  ) : null}
                  <div className="sm:col-span-2 sm:flex-no-wrap">
                    <div className="my-auto flex items-center">
                      <button
                        type="submit"
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 items-center"
                        disabled={disableSubmitButton}
                      >
                        {disableSubmitButton ? (
                          <svg
                            className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        ) : (
                          "Scrape"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div
            className={classNames(dataLoaded ? "" : "hidden", "relative p-4")}
          >
            {scrapeResult.scrapeArray.error ? (
              scrapeResult.scrapeArray.error
            ) : (
              <>
                <p className="text-sm">
                  Price: {scrapeResult.scrapeArray.scrapedPrice}
                </p>

                <p className="mt-4 text-sm">
                  Stock status:{" "}
                  {scrapeResult.scrapeArray.stock === 1
                    ? "In stock"
                    : "Out of stock"}
                </p>
              </>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currency: state.auth.currencyLabel,
    // jwt: state.auth.jwt,
    userId: state.auth.userId,
  };
};

export default connect(mapStateToProps)(RunScrapeTest);
