import { Fragment, useEffect, useRef } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { ChevronDownIcon } from "@heroicons/react/24/solid";

import { Menu, Transition } from "@headlessui/react";

import ImportProductsModal from "../../../../components/ModalTemplates/ImportProductsModal";
import ProductQuickEditForm from "../../../../components/forms/product/ProductQuickEditForm";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const tabs = [
  { name: "Active Products", href: "#" },
  { name: "Archived Products", href: "#" },
];

export const ProductsHeaderArea = (props) => {
  const {
    brands,
    categories,
    changeProductTabs,
    checkRequestError,
    currency,
    currentTab,
    customerId,
    fetchAllBrands,
    fetchAllCategories,
    refreshProductsList,
    handleSearch,
    loading,
    openActionModal,
    setOpenSidePanel,
    setShow,
    setSidePanelExtra,
    setOpenActionModal,
    totalProducts,
    userId,
  } = props;
  const searchRef = useRef();

  return (
    <>
      <div className="grid grid-cols-2">
        <div className="sm:hidden sm:col-span-2">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full border-gray-300 rounded-md"
            defaultValue={tabs[currentTab].name}
          >
            {tabs.map((tab, index) => (
              <option key={index}>
                {index === currentTab
                  ? tab.name + " (" + totalProducts + ")"
                  : tab.name}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block sm:col-span-1">
          <nav
            className="relative z-0 flex divide-x divide-gray-200"
            aria-label="Tabs"
          >
            {tabs.map((tab, index) => (
              <a
                key={tab.name}
                href={tab.href}
                className={classNames(
                  index === currentTab
                    ? "text-gray-900"
                    : "text-gray-500 hover:text-gray-700 bg-gray-200",
                  index === 0 ? "" : "",
                  index === tabs.length - 1 ? "" : "",
                  "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center focus:z-10"
                )}
                aria-current={index === currentTab ? "page" : undefined}
                onClick={() => {
                  if (loading) {
                    return;
                  } else {
                    changeProductTabs(index);
                  }
                }}
              >
                <span>
                  {index === currentTab
                    ? tab.name + " (" + totalProducts + ")"
                    : tab.name}
                </span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    index === currentTab ? "" : "bg-transparent",
                    "absolute inset-x-0 bottom-0 h-0.5"
                  )}
                />
              </a>
            ))}
          </nav>
        </div>
      </div>
      {/* Tabs end here */}
      {/* Start here */}
      <div className="px-4 sm:px-0">
        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-12 rounded-xl">
          <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div className="ml-4 mt-2">
              {/* search starts */}
              <div className="mt-1 flex rounded-md">
                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                  <input
                    type="search"
                    name="searchProduct"
                    id="searchProduct"
                    className="block w-full rounded-lg rounded-l-md pl-2 sm:text-md border border-gray-300 rounded-tr-none rounded-br-none"
                    placeholder="Search"
                    onChange={handleSearch}
                    ref={searchRef}
                  />
                </div>
                <button
                  type="button"
                  className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1"
                  onClick={() => handleSearch()}
                >
                  <MagnifyingGlassIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </button>
              </div>
              {/* search ends */}
            </div>
            <div className="ml-4 mt-2 flex-shrink-0">
              <button
                type="button"
                className="relative inline-flex items-center mr-2 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                onClick={() => {
                  setSidePanelExtra({
                    childComponent: (
                      <ProductQuickEditForm
                        brands={brands}
                        categories={categories}
                        checkRequestError={checkRequestError}
                        currency={currency}
                        customerId={customerId}
                        fetchAllBrands={fetchAllBrands}
                        fetchAllCategories={fetchAllCategories}
                        isEdit={false}
                        product={null}
                        refreshProductsList={refreshProductsList}
                        setOpen={setOpenSidePanel}
                        setShow={setShow}
                        setSidePanelExtra={setSidePanelExtra}
                        subtitle="Fill in the information below to add a new product."
                        title="Add new product"
                      />
                    ),
                  });
                  setOpenSidePanel(true);
                }}
              >
                Add product
              </button>

              <span className="relative inline-flex shadow-sm rounded-md mr-2">
                <Menu as="span" className="-ml-px relative block">
                  <Menu.Button className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-400 hover:bg-green-500">
                    <span className="sr-only">Open options</span>
                    Import Products
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="cursor-pointer origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-900">
                      <div className="py-1 z-150">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm "
                              )}
                            >
                              Import products
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm "
                              )}
                            >
                              Import costs only
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm "
                              )}
                              onClick={() => {
                                setOpenActionModal({
                                  open: true,
                                  setOpen: setOpenActionModal,
                                  childComponent: (
                                    <ImportProductsModal
                                      customerId={customerId}
                                      refreshProductsList={refreshProductsList}
                                      importType={2}
                                      open={openActionModal}
                                      setOpenActionModal={setOpenActionModal}
                                      setShow={setShow}
                                      userId={userId}
                                    />
                                  ),
                                });
                              }}
                            >
                              Import competitors
                            </a>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
