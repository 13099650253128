import { Dialog } from "@headlessui/react";

export default function AuditExplanationTemplate(props) {
  const { productName, type } = props;

  const getExplanation = () => {
    let explanation = "";
    switch (type) {
      case 0:
        explanation = (
          <font>
            Are you sure you want to{" "}
            <font className="text-medium text-red-500">
              audit {productName}
            </font>
            ? This means it will audit the competitors and apply the pricing
            rules you set. The dynamic price calcuated for the product will not
            be updated on your website however. If you want to do that, then
            please run the Audit and Update price.
          </font>
        );
        break;
      case 1:
        explanation = (
          <font>
            Are you sure you want to{" "}
            <font className="text-medium text-red-500">
              audit and update the price for {productName}
            </font>
            ? This means it will audit the competitors and apply the pricing
            rules you set. The dynamic price calcuated for the product will not
            be updated on your website however. If you want to do that, then
            please run the Audit and Update price.
          </font>
        );
        break;
      case 2:
        explanation = (
          <font>
            Are you sure you want to{" "}
            <font className="text-medium text-red-500">
              audit all the products in your account
            </font>
            ? This means it will audit the competitors and apply the pricing
            rules you set. The dynamic price calcuated for the product will not
            be updated on your website however. If you want to do that, then
            please run the Audit and Update price.
          </font>
        );
        break;
      case 3:
        explanation = (
          <font>
            Are you sure you want to{" "}
            <font className="text-medium text-red-500">
              audit and update the price for all the products in your account?
            </font>
            ? This means it will audit the competitors and apply the pricing
            rules you set. The dynamic price calcuated for the product will not
            be updated on your website however. If you want to do that, then
            please run the Audit and Update price.
          </font>
        );
        break;
    }
    return explanation;
  };

  return (
    <div>
      <div>
        {/* <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
          <ExclamationCircleIcon
            className="h-6 w-6 text-red-600"
            aria-hidden="true"
          />
        </div> */}
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-lg leading-6 font-medium text-gray-900"
          >
            {getExplanation}
          </Dialog.Title>
        </div>
      </div>
    </div>
  );
}
