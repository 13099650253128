import React, { Fragment, useEffect } from "react";

import { Menu, Transition } from "@headlessui/react";

import TooltipText from "../../../../components/TooltipText";

import { ChevronDownIcon } from "@heroicons/react/24/solid";

import { formatDate } from "../../../../shared/utility";
import ScrapeResults from "../../../../components/ModalTemplates/ScrapeResults";
import { ProductScrapesQuickActions } from "./subComponents/ProductScrapesQuickActions";

export default function ProductScrapes(props) {
  const {
    checkRequestError,
    customerId,
    fetchProductScrapes,
    product,
    scrapes,
    scrapeSingleProduct,
    setEmptyModal,
    setOpenSidePanel,
    setShow,
    setSidePanelExtra,
  } = props;

  useEffect(() => {
    fetchProductScrapes();
  }, []);

  useEffect(() => {
    // const viewAuditId = searchParams.get("audit");
    // if (viewAuditId > 0) {
    //   setSidePanelExtra({
    //     childComponent: (
    //       <ViewScrapeDetails
    //         checkRequestError={checkRequestError}
    //         isRedirect={true}
    //         productId={productId}
    //         scrape={viewAuditId}
    //         setOpen={setOpenSidePanel}
    //         subtitle=""
    //         userId={userId}
    //         title={""}
    //       />
    //     ),
    //     size: "7xl",
    //   });
    //   setOpenSidePanel(true);
    // }
  }, [product]);

  return (
    <div className="justify-between px-4">
      <div className="divide-y divide-gray-500">
        <div className="flex items-center justify-between flex-wrap sm:flex-nowrap space-y-3 pt-6 pb-5">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Audit Log
          </h3>
          <div className="sm:flex-no-wrap px-4 sm:px-12">
            <span className="relative z-0 inline-flex shadow-sm rounded-md">
              <Menu as="span" className="-ml-px relative block">
                <Menu.Button className="relative inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none z-900">
                  <span className="sr-only">Open options</span>
                  Run Audit
                  <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-900">
                    <div className="py-1">
                      <Menu.Item>
                        <button
                          type="button"
                          className="text-gray-700 block px-4 py-2 text-sm z-900"
                          onClick={() => {
                            setEmptyModal({
                              childComponent: (
                                <ScrapeResults
                                  productId={product.productId}
                                  productName={product.name}
                                  scrapeSingleProduct={scrapeSingleProduct}
                                  state=""
                                  setOpen={setEmptyModal}
                                  type={0}
                                />
                              ),
                              open: true,
                            });
                          }}
                        >
                          Run audit only
                        </button>
                      </Menu.Item>
                      <Menu.Item>
                        <button
                          type="button"
                          className="text-gray-700 block px-4 py-2 text-sm z-900"
                          onClick={() => {
                            setEmptyModal({
                              childComponent: (
                                <ScrapeResults
                                  productId={product.productId}
                                  productName={product.name}
                                  scrapeSingleProduct={scrapeSingleProduct}
                                  state=""
                                  setOpen={setEmptyModal}
                                  type={1}
                                />
                              ),
                              open: true,
                            });
                          }}
                        >
                          Run audit and price update
                        </button>
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </span>
          </div>
        </div>
        <div className="space-y-3 pt-6 pb-5 mt-8">
          <div className="">
            <div className="">
              <div className="align-middle inline-block w-full">
                <div className="shadow border-b border-gray-200 sm:rounded-lg">
                  <table className="divide-y divide-gray-200 table-auto w-full">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          width="20%"
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          width="20%"
                        >
                          Dynamic Price
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          width="20%"
                        >
                          API used?
                        </th>
                        <th scope="col" className="relative px-6 py-3">
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {scrapes.map((scrape, index) => (
                        <tr key={index}>
                          <td
                            className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400"
                            width="20%"
                          >
                            {formatDate(scrape.addedDate)}
                          </td>
                          <td
                            className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400"
                            width="20%"
                          >
                            {scrape.dynamicPrice}
                          </td>
                          <td
                            className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400"
                            width="20%"
                          >
                            {scrape.updatePriceTurnedOn === "1" ? (
                              scrape.apiSuccess === "1" ? (
                                <TooltipText
                                  explanation="Successfully updated price on your website"
                                  text="Yes"
                                  textColor="text-green-700 font-medium"
                                />
                              ) : (
                                <TooltipText
                                  explanation="Error while updating price on your website"
                                  text="No"
                                  textColor="text-red-400 font-medium"
                                />
                              )
                            ) : (
                              <TooltipText
                                explanation="API integration is not turned on for this product"
                                text="Not turned on"
                                textColor="text-yellow-400 font-medium"
                              />
                            )}
                          </td>
                          <td
                            className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                            width="20%"
                          >
                            <ProductScrapesQuickActions
                              checkRequestError={checkRequestError}
                              customerId={customerId}
                              productId={product.productId}
                              scrape={scrape}
                              setEmptyModal={setEmptyModal}
                              setOpenSidePanel={setOpenSidePanel}
                              setShow={setShow}
                              setSidePanelExtra={setSidePanelExtra}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
