import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";

import { ChevronDownIcon } from "@heroicons/react/24/solid";
import AssignCompetitorForm from "../../../../../components/forms/product/AssignCompetitorForm";
import AssignPricingRuleToCompetitorForm from "../../../../../components/forms/product/AssignPricingRuleToCompetitorForm";

import DeleteProductTemplate from "../../../../../components/ModalTemplates/DeleteProductTemplate";
import ReportUrl from "../../../../../components/ModalTemplates/ReportUrl";
import { deleteAssignedCompetitorApiCall } from "../../../../../domain/networkCalls/networkCalls";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const ProductTabCompetitorQuickActions = (props) => {
  const {
    checkRequestError,
    competitor,
    currency,
    customerId,
    deleteProductCompetitorFromState,
    fetchAllRules,
    openActionModal,
    product,
    productIndex,
    rules,
    setEmptyModal,
    setErrorModalOpen,
    setOpenActionModal,
    setOpenSidePanel,
    setShow,
    setSidePanelExtra,
    updateSingleProductCompetitor,
  } = props;

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const deleteAssignedCompetitor = async function (rowId, urlId) {
    setDisableSubmitButton(true);
    setOpenActionModal({
      ...openActionModal,
      buttonDisabled: true,
      itemId: 0,
    });
    const callResult = await deleteAssignedCompetitorApiCall(customerId, urlId);

    setOpenActionModal({
      buttonDisabled: false,
      open: false,
      setOpen: setOpenActionModal,
    });

    if (callResult.success) {
      deleteProductCompetitorFromState(rowId, urlId);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle:
            "We could not sync with your Shopify account, please try again.",
        });
      }
      setErrorModalOpen({
        open: true,
        title: "Sorry!",
        subtitle: "We could not delete the competitor, please try again.",
      });
      setDisableSubmitButton(false);
    }
  };

  return (
    <>
      <span className="relative inline-flex shadow-sm rounded-md">
        <button
          className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
          onClick={() => {
            setSidePanelExtra({
              childComponent: (
                <AssignCompetitorForm
                  customerId={customerId}
                  checkRequestError={checkRequestError}
                  competitor={{ ...competitor, productId: product.productId }}
                  isEdit={true}
                  setOpen={setOpenSidePanel}
                  subtitle="Fill in the information below to edit competitor."
                  title={"Editing " + competitor.parentDomain}
                  updateSingleProductCompetitor={updateSingleProductCompetitor}
                />
              ),
            });
            setOpenSidePanel(true);
          }}
        >
          Edit
        </button>
        <Menu as="span" className="-ml-px relative block">
          <Menu.Button className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none">
            <span className="sr-only">Open options</span>
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="cursor-pointer origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-900">
              <div className="py-1 z-150 text-left">
                <Menu.Item>
                  {({ active }) => (
                    <a
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                      onClick={() => {
                        setSidePanelExtra({
                          childComponent: (
                            <AssignPricingRuleToCompetitorForm
                              checkRequestError={checkRequestError}
                              competitorName={competitor.parentDomain}
                              currency={currency}
                              fetchAllRules={fetchAllRules}
                              source={true}
                              isEdit={competitor.pr_product_ruleId > 0}
                              competitor={{
                                rowId: competitor.urlId,
                                itemType: 0,
                                itemId: competitor.urlId,
                                ruleId: competitor.pr_product_ruleId,
                                explanation: competitor.prod_rule_explanation,
                                ruleAssignId: competitor.prodRuleAssignId,
                                assignLevel: 1,
                                assignItemId: product.productId,
                                ruleArray: competitor.ruleArray,
                                competitorId: competitor.competitorId,
                              }}
                              rules={rules}
                              setOpen={setOpenSidePanel}
                              subtitle="Pricing rules affect the dynamic price of a product on a competitor level."
                              title={
                                "Assign a pricing rule to " +
                                product.productName
                              }
                              updateSingleProductCompetitor={
                                updateSingleProductCompetitor
                              }
                              customerId={customerId}
                            />
                          ),
                          panelSize: "2xl",
                        });
                        setOpenSidePanel(true);
                      }}
                    >
                      {competitor.pr_product_ruleId > 0 ||
                      competitor.pr_competitor_ruleId > 0
                        ? "Edit rule"
                        : "Assign rule"}
                    </a>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <a
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                      disabled={!competitor.scrapeId > 0}
                      onClick={() => {
                        setEmptyModal({
                          childComponent: (
                            <ReportUrl
                              checkRequestError={checkRequestError}
                              disableSubmitButton={disableSubmitButton}
                              explanation="Report this url if you its not auditing properly, please explain the issue in a few sentences below for us."
                              itemId={competitor.urlId}
                              setOpen={setEmptyModal}
                              setShow={setShow}
                              title="Report url"
                              customerId={customerId}
                            />
                          ),
                          open: true,
                        });
                      }}
                    >
                      Report Url
                    </a>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <a
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                      onClick={() => {
                        setOpenActionModal({
                          open: true,
                          setOpen: setOpenActionModal,
                          childComponent: (
                            <DeleteProductTemplate
                              actionFunc={deleteAssignedCompetitor}
                              buttonDisabled={false}
                              buttonMessage={"Delete"}
                              customerId={customerId}
                              disableSubmitButton={disableSubmitButton}
                              index={productIndex}
                              itemId={competitor.urlId}
                              message={
                                "Type delete below to delete " +
                                competitor.parentDomain +
                                " as competitor from " +
                                product.name +
                                "."
                              }
                              setOpen={setOpenActionModal}
                            />
                          ),
                        });
                      }}
                    >
                      Delete competitor
                    </a>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </span>
    </>
  );
};
