import { ClockIcon } from "@heroicons/react/24/solid";

import { PlusIcon } from "@heroicons/react/24/outline";

import { formatDate } from "../../../../shared/utility";
import TooltipText from "../../../../components/TooltipText";

import { CustomerCompetitorsQuickActions } from "./subComponents/CustomerCompetitorsQuickActions";

export const CustomerCompetitorsTable = (props) => {
  const {
    checkRequestError,
    competitors,
    currency,
    customerId,
    deleteProductCompetitorFromTable,
    fetchAllRules,
    openActionModal,
    rules,
    setErrorModalOpen,
    setOpenActionModal,
    setOpenSidePanel,
    setSidePanelExtra,
    updateCompetitorInParentDomains,
  } = props;

  return (
    <>
      {/* table starts */}
      <div className="bg-white mb-6 px-4 py-5 border-b border-gray-200 sm:px-12">
        <div className="flex flex-col pt-6">
          <div className="-my-2 overflow-x-visible sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-visible border-b border-gray-200 sm:rounded-lg  w-full">
                <table className="w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Competitor
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Rule name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Pricing rule
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {competitors.map((competitor) => (
                      <tr key={competitor.competitorId}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {competitor.parentDomain}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <TooltipText
                            explanation={competitor.explanation}
                            text={competitor.ruleName}
                          />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <CustomerCompetitorsQuickActions
                            checkRequestError={checkRequestError}
                            competitor={competitor}
                            customerId={customerId}
                            currency={currency}
                            deleteProductCompetitorFromTable={
                              deleteProductCompetitorFromTable
                            }
                            fetchAllRules={fetchAllRules}
                            openActionModal={openActionModal}
                            rules={rules}
                            setErrorModalOpen={setErrorModalOpen}
                            setOpenActionModal={setOpenActionModal}
                            setOpenSidePanel={setOpenSidePanel}
                            setSidePanelExtra={setSidePanelExtra}
                            updateCompetitorInParentDomains={
                              updateCompetitorInParentDomains
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
