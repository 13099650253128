import { useState } from "react";
import { getAllProductsInPryceHubApiCall } from "../../domain/networkCalls/networkCalls";

export function ProductsViewModel(checkRequestError, setErrorModalOpen) {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [products, setProducts] = useState({ productList: [], total: 0 });

  async function getAllProductsInPryceHub(page, search, status) {
    const callResult = await getAllProductsInPryceHubApiCall(
      page,
      search,
      status
    );

    setLoading(false);
    if (callResult.success) {
      console.log("callresult:", callResult.data);
      setProducts({
        productList: callResult.data.products,
        total: callResult.data.totalProductsForTab,
      });
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Oops",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
      }
    }
  }

  return {
    getAllProductsInPryceHub,
    loading,
    page,
    products,
  };
}
