const prod = {
  url: {
    API_URL: "https://app-admin-api.prycehub.com/api/",
    FILES_URL: "https://api.prycehub.com/api/",
  },
};

const dev = {
  url: {
    API_URL: "https://stg-adminapi.prycehub.com/api/",
    FILES_URL: "https://api.prycehub.com/api/",
  },
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;

export const parentDomain = "parentDomain";
export const sourceProductsPage = "sourceProductsPage";
