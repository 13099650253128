import { useState } from "react";
import { getCustomersApiCall } from "../../domain/networkCalls/networkCalls";

export function CustomersViewModel() {
  const [errorModalOpen, setErrorModalOpen] = useState({
    open: false,
    title: null,
    subtitle: null,
  });
  const [customers, setCustomers] = useState([]);
  const [page, setPage] = useState(1);

  async function getCustomers(checkRequestError, page, search) {
    const callResult = await getCustomersApiCall(page, search);

    if (callResult.success) {
      setCustomers(callResult.data.customers);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Oops",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
      }
    }
  }

  return {
    customers,
    errorModalOpen,
    getCustomers,
    page,
    setErrorModalOpen,
  };
}
