import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  useLocation,
  useNavigate,
  Link,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import { Dialog, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

import * as actions from "../store/actions/index";

import routes from "./routes";
import AllDomains from "../views/domains/AllDomains";
import Dashboard from "../views/dashboard/Dashboard";
import Customers from "../views/customers/Customers";
import ViewCustomer from "../views/customers/customer/ViewCustomer";
import ProductDetails from "../views/products/product/ProductDetails";
import RunScrapeTest from "../views/scrape-test/RunScrapeTest";
import Products from "../views/products/Products";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Admin = (props) => {
  const { userId, userStatus, userType } = props;

  let navigate = useNavigate();

  const { onLogout } = props;
  const location = useLocation();

  const [notifications, setNotifications] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const changeDestination = (destination) => {
    navigate(destination);
  };

  const getRoutes = routes(userType, location.pathname);
  const routesArray = getRoutes["routes"];

  const checkRequestError = (errorStatus) => {
    if (errorStatus === 401) {
      onLogout();
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-indigo-700">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                    <div className="flex flex-shrink-0 items-center px-4">
                      <img
                        className="h-12 w-auto"
                        src="https://prycehub.com/wp-content/uploads/2022/08/PryceHub-Logo-500-%C3%97-500-px-Transparent-e1660664657695.png?color=indigo&shade=300"
                        alt="PryceHub Inc."
                      />
                    </div>
                    <nav className="mt-5 space-y-1 px-2">
                      {routesArray.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-indigo-800 text-white"
                              : "text-white hover:bg-indigo-600 hover:bg-opacity-75",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                        >
                          <item.icon
                            className="mr-4 h-6 w-6 flex-shrink-0 text-indigo-300"
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ))}
                    </nav>
                  </div>
                  <div className="flex flex-shrink-0 border-t border-indigo-800 p-4">
                    <a href="#" className="group block flex-shrink-0">
                      <div className="flex items-center">
                        <div>
                          <img
                            className="inline-block h-10 w-10 rounded-full"
                            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                            alt=""
                          />
                        </div>
                        <div className="ml-3">
                          <p className="text-base font-medium text-white">
                            Tom Cook2
                          </p>
                          <p className="text-sm font-medium text-indigo-200 group-hover:text-white">
                            View profile
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex min-h-0 flex-1 flex-col bg-indigo-700">
            <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
              <div className="flex flex-shrink-0 items-center px-4">
                <img
                  className="h-12 w-auto"
                  src="https://prycehub.com/wp-content/uploads/2022/08/PryceHub-Logo-500-%C3%97-500-px-Transparent-e1660664657695.png?color=indigo&shade=300"
                  alt="PryceHub Inc."
                />
              </div>
              <nav className="mt-5 flex-1 space-y-1 px-2">
                {routesArray.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-indigo-800 text-white"
                        : "text-white hover:bg-indigo-600 hover:bg-opacity-75",
                      "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                    )}
                  >
                    <item.icon
                      className="mr-3 h-6 w-6 flex-shrink-0 text-indigo-300"
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>
                ))}
              </nav>
            </div>
            <div className="flex flex-shrink-0 border-t border-indigo-800 p-2">
              <nav className="flex-1 space-y-1 px-2">
                <a
                  onClick={onLogout}
                  className={
                    "text-white hover:bg-indigo-600 hover:bg-opacity-75 group flex items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer"
                  }
                >
                  Log out
                </a>
              </nav>
            </div>
            <div className="flex flex-shrink-0 border-t border-indigo-800 p-4">
              <a href="#" className="group block w-full flex-shrink-0">
                <div className="flex items-center">
                  <div>
                    <img
                      className="inline-block h-9 w-9 rounded-full"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-white">Tom Cook3</p>
                    <p className="text-xs font-medium text-indigo-200 group-hover:text-white">
                      View profile
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col md:pl-64">
          <div className="sticky top-0 z-10 bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <Routes>
            <Route
              path="/customers/:customerId/:pageRedirect"
              element={<ViewCustomer checkRequestError={checkRequestError} />}
            />
            <Route
              path="/customers/:customerId"
              element={<ViewCustomer checkRequestError={checkRequestError} />}
            />
            <Route
              path="/customers"
              element={<Customers checkRequestError={checkRequestError} />}
            />
            <Route
              path="/dashboard"
              element={<Dashboard checkRequestError={checkRequestError} />}
            />
            <Route
              path="/domains"
              element={<AllDomains checkRequestError={checkRequestError} />}
            />
            <Route
              path="/products/:productId"
              element={<ProductDetails checkRequestError={checkRequestError} />}
            />
            <Route
              path="/products"
              element={<Products checkRequestError={checkRequestError} />}
            />
            <Route
              path="/reported-urls"
              element={<Dashboard checkRequestError={checkRequestError} />}
            />
            <Route
              path="/run-scrape-test"
              element={<RunScrapeTest checkRequestError={checkRequestError} />}
            />
            <Route
              path="/test-scrape"
              element={<RunScrapeTest checkRequestError={checkRequestError} />}
            />

            <Route path="*" element={<Navigate to="/dashboard" replace />} />
          </Routes>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    userStatus: state.auth.userStatus,
    userType: state.auth.userType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { onLogout: () => dispatch(actions.logout()) };
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
