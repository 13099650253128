import { useState } from "react";
import {
  getCustomersDetailsApiCall,
  updateCustomerDetailsApiCall,
} from "../../domain/networkCalls/networkCalls";

export function CustomerDetailsViewModel(checkRequestError, setShow) {
  const [customer, setCustomer] = useState([]);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState({
    open: false,
    title: null,
    subtitle: null,
  });
  const [openSidePanel, setOpenSidePanel] = useState(false);
  const [sidePanelExtra, setSidePanelExtra] = useState({
    childComponent: null,
  });

  async function getCustomerDetails(customerId) {
    const callResult = await getCustomersDetailsApiCall(customerId);

    if (callResult.success) {
      setCustomer(callResult.data.customer);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Oops",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
      }
    }
  }

  async function updateCustomerDetails(data) {
    setDisableSubmitButton(true);
    const callResult = await updateCustomerDetailsApiCall(data);

    if (callResult.success) {
      setCustomer(callResult.data.customer);
      setShow({ show: true, title: "Customer updated." });
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Oops",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
      }
    }
    setDisableSubmitButton(false);
  }

  return {
    customer,
    disableSubmitButton,
    errorModalOpen,
    getCustomerDetails,
    setErrorModalOpen,
    updateCustomerDetails,
    openSidePanel,
    setOpenSidePanel,
    sidePanelExtra,
    setSidePanelExtra,
  };
}
