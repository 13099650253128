import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import { useParams, useSearchParams } from "react-router-dom";
import { ProductDetailsViewModel } from "../../../viewmodels/products/ProductDetailsViewModel";

import ProductDetailsForm from "../../../components/forms/product/ProductDetailsForm";
import ProductCompetitors from "./competitors/ProductCompetitors";
import ProductScrapes from "./scrapes/ProductScrapes";

import ActionModal from "../../../components/ModalTemplates/ActionModal";
import EmptyModal from "../../../components/ModalTemplates/EmptyModal";
import ErrorModal from "../../../components/ModalTemplates/ErrorModal";
import SidePanel from "../../../components/ModalTemplates/SidePanel";

import SuccessNotification from "../../../components/ModalTemplates/SuccessNotification";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const tabs = [
  { name: "Product Details", href: "#" },
  { name: "Competitors", href: "#" },
  { name: "Audit Log", href: "#" },
  { name: "Analytics", href: "#" },
];

const ProductDetails = (props) => {
  const { checkRequestError, currency } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const params = useParams();

  const productId = params.productId;

  const {
    addNewCompetitorToProduct,
    brands,
    categories,
    competitors,
    currentTab,
    customerId,
    deleteAssignedCompetitor,
    emptyModal,
    errorModalOpen,
    getProductCompetitors,
    fetchAllBrands,
    fetchAllCategories,
    fetchAllRules,
    fetchProductById,
    fetchProductScrapes,
    loading,
    openActionModal,
    openSidePanel,
    product,
    rules,
    scrapes,
    scrapeSingleProduct,
    setCurrentTab,
    setEmptyModal,
    setErrorModalOpen,
    setLoading,
    setOpenActionModal,
    setOpenSidePanel,
    setSidePanelExtra,
    setShow,
    setSubmitError,
    sidePanelExtra,
    show,
    submitError,
    updateProductInState,
    updateSingleProductCompetitor,
  } = ProductDetailsViewModel(checkRequestError, productId);

  useEffect(() => {
    document.title = "Loading...";

    fetchProductById(productId);
  }, [productId]);

  useEffect(() => {
    if (product) {
      document.title = "Editing: " + product.name;

      getProductCompetitors();
      fetchAllRules();

      const redirect = searchParams.get("redirect");
      if (redirect == "audit-logs") {
        setCurrentTab(2);
      }
    }
  }, [product]);

  const renderComponent = (index) => {
    switch (index) {
      case 0:
        return (
          <div key={0} className="-mt-2 bg-white">
            {loading ? (
              "loading"
            ) : (
              <ProductDetailsForm
                brands={brands}
                categories={categories}
                checkRequestError={checkRequestError}
                currency={currency}
                customerId={customerId}
                fetchAllBrands={fetchAllBrands}
                fetchAllCategories={fetchAllCategories}
                product={product}
                updateProductInState={updateProductInState}
                setErrorModalOpen={setErrorModalOpen}
              />
            )}
          </div>
        );

      case 1:
        return (
          <div key={1} className="-mt-2 bg-white">
            <ProductCompetitors
              addNewCompetitorToProduct={addNewCompetitorToProduct}
              checkRequestError={checkRequestError}
              competitors={competitors}
              currency={currency}
              customerId={customerId}
              deleteAssignedCompetitor={deleteAssignedCompetitor}
              fetchAllRules={fetchAllRules}
              openActionModal={openActionModal}
              product={product}
              rules={rules}
              setEmptyModal={setEmptyModal}
              setErrorModalOpen={setErrorModalOpen}
              setOpenActionModal={setOpenActionModal}
              setOpenSidePanel={setOpenSidePanel}
              setShow={setShow}
              setSidePanelExtra={setSidePanelExtra}
              updateSingleProductCompetitor={updateSingleProductCompetitor}
            />
          </div>
        );

      case 2:
        return (
          <div key={2} className="-mt-2 bg-white">
            <ProductScrapes
              checkRequestError={checkRequestError}
              customerId={customerId}
              fetchProductScrapes={fetchProductScrapes}
              product={product}
              scrapes={scrapes}
              scrapeSingleProduct={scrapeSingleProduct}
              setEmptyModal={setEmptyModal}
              setOpenSidePanel={setOpenSidePanel}
              setShow={setShow}
              setSidePanelExtra={setSidePanelExtra}
            />
          </div>
        );

      default:
        return (
          <div key={1000} className="-mt-2 bg-white">
            Could not load page, please try again
          </div>
        );
    }
  };

  return (
    <div className="bg-gray-50">
      <header className="bg-white shadow">
        <div className="max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900">
            {loading
              ? "Loading..."
              : product.name +
                " // " +
                product.firstname +
                " " +
                product.lastname +
                " " +
                product.email}
          </h1>
        </div>
      </header>

      <main>
        <div className="mx-auto py-6 sm:px-6 lg:px-8">
          {/* Content starts here */}

          {/* tab here */}
          <div>
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              <select
                id="tabs"
                name="tabs"
                className="block w-full  border-gray-300 rounded-md"
                defaultValue={tabs[currentTab].name}
              >
                {tabs.map((tab, index) => (
                  <option key={index}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <nav
                className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200"
                aria-label="Tabs"
              >
                {tabs.map((tab, index) => (
                  <a
                    key={tab.name}
                    href={tab.href}
                    className={classNames(
                      index === currentTab
                        ? "text-gray-900"
                        : "text-gray-500 hover:text-gray-700",
                      index === 0 ? "rounded-l-lg" : "",
                      index === tabs.length - 1 ? "rounded-r-lg" : "",
                      "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                    )}
                    aria-current={index === currentTab ? "page" : undefined}
                    onClick={() => setCurrentTab(index)}
                  >
                    <span>{tab.name}</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        index === currentTab
                          ? "bg-indigo-500"
                          : "bg-transparent",
                        "absolute inset-x-0 bottom-0 h-0.5"
                      )}
                    />
                  </a>
                ))}
              </nav>
            </div>
          </div>

          {/* {tab content starts below} */}
          <div className="px-4 py-6 sm:px-0">
            <div className="sm:col-span-9 py-5 border-b border-gray-200">
              {tabs.map((item, index) =>
                index === currentTab ? renderComponent(index) : null
              )}
            </div>
          </div>
        </div>
        <ActionModal
          childComponent={openActionModal.childComponent}
          disableSubmitButton={openActionModal.buttonDisabled}
          buttonMessage={openActionModal.buttonMessage}
          message={openActionModal.message}
          open={openActionModal.open}
          itemId={openActionModal.productId}
          setOpen={setOpenActionModal}
        />
        <EmptyModal
          childComponent={emptyModal.childComponent}
          open={emptyModal.open}
          setOpen={setEmptyModal}
        />
        <ErrorModal
          open={errorModalOpen.open}
          title={errorModalOpen.title}
          setOpen={setErrorModalOpen}
          subtitle={errorModalOpen.subtitle}
        />
        <SidePanel
          open={openSidePanel}
          setOpen={setOpenSidePanel}
          childComponent={sidePanelExtra.childComponent}
          size={sidePanelExtra.size}
        />
        <SuccessNotification
          show={show.show}
          setShow={setShow}
          title={show.title}
        />
      </main>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currency: state.auth.currencyLabel,
    userId: state.auth.userId,
  };
};

export default connect(mapStateToProps)(ProductDetails);
