import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { CustomerDetailsViewModel } from "../../../viewmodels/customers/CustomerDetailsViewModel";

import { useNavigate, useParams } from "react-router-dom";

import ActionModal from "../../../components/ModalTemplates/ActionModal";
import EmptyModal from "../../../components/ModalTemplates/EmptyModal";
import ErrorModal from "../../../components/ModalTemplates/ErrorModal";
import CustomerDetailsForm from "../../../components/forms/customer/CustomerDetailsForm";
import SuccessNotification from "../../../components/ModalTemplates/SuccessNotification";
import { CustomerProducts } from "./products/CustomerProducts";
import SidePanel from "../../../components/ModalTemplates/SidePanel";
import { CustomerCompetitors } from "./competitors/CustomerCompetitors";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const tabs = [
  { name: "Details", href: "javascript:void(0);" },
  { name: "Products", href: "javascript:void(0);" },
  { name: "Competitors", href: "javascript:void(0)" },
  { name: "Pricing Plans", href: "javascript:void(0)" },
];

const Customers = (props) => {
  const { checkRequestError, userId } = props;

  const navigate = useNavigate();
  const params = useParams();

  const customerId = params.customerId;

  const [currentTab, setCurrentTab] = useState(0);
  const [openActionModal, setOpenActionModal] = useState({
    open: false,
    title: null,
    subtitle: null,
  });
  const [emptyModal, setEmptyModal] = useState({
    open: false,
    childComponent: null,
  });
  const [show, setShow] = useState({ show: false });

  const {
    addNewCompetitorToProduct,
    customer,
    disableSubmitButton,
    errorModalOpen,
    getCustomerDetails,
    setErrorModalOpen,
    updateCustomerDetails,
    openSidePanel,
    setOpenSidePanel,
    sidePanelExtra,
    setSidePanelExtra,
  } = CustomerDetailsViewModel(checkRequestError, setShow);

  document.title = "Loading...";

  useEffect(() => {
    getCustomerDetails(customerId);
  }, []);

  useEffect(() => {
    if (customer.firstname) {
      document.title = customer.firstname + " " + customer.lastname;
    }
  }, [customer]);

  const renderComponent = (index) => {
    switch (index) {
      case 0:
        return (
          <div key={0} className="-mt-2 bg-white">
            <CustomerDetailsForm
              actionFunc={updateCustomerDetails}
              customer={customer}
              disableSubmitButton={disableSubmitButton}
            />
          </div>
        );

      case 1:
        return (
          <div key={1} className="-mt-2">
            <CustomerProducts
              addNewCompetitorToProduct={addNewCompetitorToProduct}
              checkRequestError={checkRequestError}
              customerId={customerId}
              currency={customer.currency}
              openActionModal={openActionModal}
              setEmptyModal={setEmptyModal}
              setErrorModalOpen={setErrorModalOpen}
              setShow={setShow}
              setSidePanelExtra={setSidePanelExtra}
              setOpenActionModal={setOpenActionModal}
              setOpenSidePanel={setOpenSidePanel}
              userId={userId}
            />
          </div>
        );

      case 2:
        return (
          <div key={1} className="-mt-2">
            <CustomerCompetitors
              checkRequestError={checkRequestError}
              currency={customer.currency}
              customerId={customerId}
              openActionModal={openActionModal}
              setErrorModalOpen={setErrorModalOpen}
              setShow={setShow}
              setSidePanelExtra={setSidePanelExtra}
              setOpenActionModal={setOpenActionModal}
              setOpenSidePanel={setOpenSidePanel}
              userId={userId}
            />
          </div>
        );

      default:
        return (
          <div key={1000} className="-mt-2 bg-white">
            Could not load page, please try again
          </div>
        );
    }
  };

  return (
    <main>
      <header className="bg-white shadow">
        <div className="max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900">
            {customer.firstname} {customer.lastname} - {customer.email}
          </h1>
        </div>
      </header>
      <div className="py-6 sm:px-6 lg:px-8">
        {/* Content starts here */}
        {/* tab here */}
        <div>
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            <select
              id="tabs"
              name="tabs"
              className="block w-full  border-gray-300 rounded-md"
              defaultValue={tabs[currentTab].name}
            >
              {tabs.map((tab, index) => (
                <option key={index}>{tab.name}</option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <nav
              className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200"
              aria-label="Tabs"
            >
              {tabs.map((tab, index) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  className={classNames(
                    index === currentTab
                      ? "text-gray-900"
                      : "text-gray-500 hover:text-gray-700",
                    index === 0 ? "rounded-l-lg" : "",
                    index === tabs.length - 1 ? "rounded-r-lg" : "",
                    "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                  )}
                  aria-current={index === currentTab ? "page" : undefined}
                  onClick={() => setCurrentTab(index)}
                >
                  <span>{tab.name}</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      index === currentTab ? "bg-indigo-500" : "bg-transparent",
                      "absolute inset-x-0 bottom-0 h-0.5"
                    )}
                  />
                </a>
              ))}
            </nav>
          </div>
        </div>

        {/* {tab content starts below} */}
        <div className="px-4 py-6 sm:px-0">
          <div className="sm:col-span-9 py-5">
            {tabs.map((item, index) =>
              index === currentTab ? renderComponent(index) : null
            )}
          </div>
        </div>
      </div>
      <ErrorModal
        open={errorModalOpen.open}
        title={errorModalOpen.title}
        setOpen={setErrorModalOpen}
        subtitle={errorModalOpen.subtitle}
      />
      <SidePanel
        open={openSidePanel}
        setOpen={setOpenSidePanel}
        childComponent={sidePanelExtra.childComponent}
        size={sidePanelExtra.size}
      />
      <SuccessNotification
        show={show.show}
        setShow={setShow}
        title={show.title}
      />

      <ActionModal
        childComponent={openActionModal.childComponent}
        disableSubmitButton={openActionModal.buttonDisabled}
        buttonMessage={openActionModal.buttonMessage}
        message={openActionModal.message}
        open={openActionModal.open}
        itemId={openActionModal.productId}
        setOpen={setOpenActionModal}
      />

      <EmptyModal
        childComponent={emptyModal.childComponent}
        open={emptyModal.open}
        setOpen={setEmptyModal}
      />
    </main>
  );
};

const mapStateToProps = (state) => {
  return {
    currency: state.auth.currencyLabel,
    // jwt: state.auth.jwt,
    userId: state.auth.userId,
  };
};

export default connect(mapStateToProps)(Customers);
