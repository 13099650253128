import { useState } from "react";
import AuditExplanationTemplate from "./AuditExplanationTemplate";

export default function ScrapeResults(props) {
  const { productId, productName, scrapeSingleProduct, setOpen, state, type } =
    props;

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const handleSubmit = () => {
    setDisableSubmitButton(true);

    scrapeSingleProduct(productId, type === 1 || type === 3 ? 1 : 0);
  };

  return (
    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
      <div>
        {state != "loading" ? (
          <AuditExplanationTemplate productName={productName} type={type} />
        ) : (
          "loading..."
        )}
      </div>
      <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 sm:col-start-2 sm:text-sm"
          disabled={disableSubmitButton}
          onClick={handleSubmit}
        >
          {disableSubmitButton ? (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            "Yes"
          )}
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
          onClick={() => setOpen({ open: false, childComponent: null })}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
