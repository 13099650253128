import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";

import { ChevronDownIcon } from "@heroicons/react/24/solid";

import { Link } from "react-router-dom";

import AssignCompetitorForm from "../../../../../components/forms/product/AssignCompetitorForm";
import ProductQuickEditForm from "../../../../../components/forms/product/ProductQuickEditForm";
import ImportCompetitorsModal from "../../../../../components/ModalTemplates/ImportCompetitorsModal";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const ProductQuickActions = (props) => {
  const {
    addNewCompetitorToProduct,
    archivedProductSuccessfully,
    brands,
    categories,
    checkRequestError,
    currency,
    customerId,
    fetchAllBrands,
    fetchAllCategories,
    openActionModal,
    product,
    queueAuditForProduct,
    refreshProductsList,
    setNewCompetitorsForProduct,
    setOpenActionModal,
    setOpenSidePanel,
    setShow,
    setSidePanelExtra,
    updateProduct,
  } = props;

  const openQuickEdit = () => {
    setSidePanelExtra({
      childComponent: (
        <ProductQuickEditForm
          archivedProductSuccessfully={archivedProductSuccessfully}
          brands={brands}
          checkRequestError={checkRequestError}
          categories={categories}
          currency={currency}
          customerId={customerId}
          fetchAllBrands={fetchAllBrands}
          fetchAllCategories={fetchAllCategories}
          isEdit={true}
          product={product}
          refreshProductsList={refreshProductsList}
          setOpen={setOpenSidePanel}
          setShow={setShow}
          setSidePanelExtra={setSidePanelExtra}
          subtitle=""
          title={"Editing " + product.name}
          updateProduct={updateProduct}
        />
      ),
    });
    setOpenSidePanel(true);
  };

  const addCompetitor = () => {
    setSidePanelExtra({
      childComponent: (
        <AssignCompetitorForm
          customerId={customerId}
          addNewCompetitorToProduct={addNewCompetitorToProduct}
          checkRequestError={checkRequestError}
          isEdit={false}
          competitor={{ productId: product.productId }}
          setOpen={setOpenSidePanel}
          subtitle="Fill in the information below to add a competitor."
          title={"Add new competitor to " + product.name}
        />
      ),
    });
    setOpenSidePanel(true);
  };

  const importCompetitors = () => {
    setOpenActionModal({
      open: true,
      setOpen: setOpenActionModal,
      childComponent: (
        <ImportCompetitorsModal
          open={openActionModal}
          productId={product.productId}
          setNewCompetitorsForProduct={setNewCompetitorsForProduct}
          setOpenActionModal={setOpenActionModal}
          setShow={setShow}
          customerId={customerId}
        />
      ),
    });
  };

  const deleteProduct = () => {
    // setOpenActionModal({
    //   open: true,
    //   setOpen: setOpenActionModal,
    //   childComponent: (
    //     <DeleteProductTemplate
    //       actionFunc={deleteProduct}
    //       disableSubmitButton={disableSubmitButton}
    //       itemId={product.productId}
    //       setOpen={setOpenActionModal}
    //       buttonDisabled={false}
    //       buttonMessage={"Delete"}
    //       message={
    //         "Type delete below to delete " +
    //         product.name +
    //         ". This action cannot be undone."
    //       }
    //     />
    //   ),
    // });
  };

  return (
    <>
      <span className="relative inline-flex shadow-sm rounded-md">
        <button
          className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
          onClick={() => openQuickEdit()}
        >
          Quick edit
        </button>
        <Menu as="span" className="-ml-px relative block">
          <Menu.Button className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none">
            <span className="sr-only">Open options</span>
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="cursor-pointer origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-900">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm "
                      )}
                      to={"/products/" + product.productId}
                    >
                      Edit
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <a
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm "
                      )}
                      onClick={() => addCompetitor()}
                    >
                      Add Competitor
                    </a>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <a
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm "
                      )}
                      onClick={() => importCompetitors()}
                    >
                      Import competitors
                    </a>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <a
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm "
                      )}
                      onClick={() => deleteProduct()}
                    >
                      Delete product
                    </a>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <a
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm "
                      )}
                      onClick={() =>
                        queueAuditForProduct(product.productId, 0, customerId)
                      }
                    >
                      Run Audit w/out API update
                    </a>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <a
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm "
                      )}
                      onClick={() =>
                        queueAuditForProduct(product.productId, 1, customerId)
                      }
                    >
                      Run Audit with API update
                    </a>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </span>
      <Link
        type="button"
        className="ml-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        to={"/products/" + product.productId + "?redirect=audit-logs"}
      >
        View latest audit
      </Link>
    </>
  );
};
