import { useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";

import { useForm } from "../useForm";

import { makeApiCall } from "../../shared/makeApiCall";

const initialFValues = {
  textAreaInput: "",
};

export default function ReportUrl(props) {
  const {
    checkRequestError,
    customerId,
    explanation,
    itemId,
    setOpen,
    setShow,
    title,
  } = props;

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("textAreaInput" in fieldValues) {
      temp.textAreaInput = "";
      if (!fieldValues.textAreaInput.length > 0) {
        temp.textAreaInput = "Please type your concern.";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const { values, errors, setErrors, handleInputChange, resetForm, setValues } =
    useForm(initialFValues, false, validate);

  useEffect(() => {
    setValues({ itemId: itemId, textAreaInput: "" });
    setErrors({});
  }, [setErrors, setErrors]);

  const submitReport = async function () {
    setDisableSubmitButton(true);
    const authData = {
      action: "reportUrl",
      customerId: customerId,
      data: values,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      resetForm();
      setDisableSubmitButton(false);
      setOpen({ open: false });
      setShow({ show: true, title: callResult.data.result });
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setDisableSubmitButton(false);
      }
    }
  };

  const handleSubmit = () => {
    if (validate()) {
      submitReport();
    }
  };

  return (
    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
      <div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-lg leading-6 font-medium text-gray-900"
          >
            {title}
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-500">{explanation}</p>
          </div>
        </div>
        <div className="mt-5">
          <textarea
            className="sm:w-full"
            labelOn={false}
            name="textAreaInput"
            onChange={handleInputChange}
            value={values.textAreaInput}
            placeholder="Please write your reason for reporting, what is wrong with it?"
          />
          {errors.textAreaInput ? (
            <p className="font-medium text-red-500">{errors.textAreaInput}</p>
          ) : null}
        </div>
      </div>
      <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 sm:col-start-2 sm:text-sm"
          onClick={() => handleSubmit()}
        >
          {disableSubmitButton ? (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            "Send"
          )}
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
          onClick={() => setOpen({ open: false })}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
