import React, { useEffect } from "react";

import { useForm } from "../../useForm";

import Input from "../../controls/Input";

import { formatDate } from "../../../shared/utility";

import "wc-spinners/dist/hollow-dots-spinner.js";

const initialFValues = {
  userId: "",
  firstname: "",
  lastname: "",
  userType: "",
  addedDate: "",
  status: "",
  respectMinPlusOrMinus: 0,
  respectMinAmount: 0,
  respectMinCurrencyOrPercentage: 0,
  respectMaxPlusOrMinus: 0,
  respectMaxAmount: 0,
  respectMaxCurrencyOrPercentage: 0,
  isCustomDecimals: 0,
  preferenceEndWith: 0,
  currency: 0,
  website: "",
  timezone: "",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CustomerDetailsForm(props) {
  const { actionFunc, disableSubmitButton, customer, submitError } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      actionFunc(values);
    }
  };

  useEffect(() => {
    if (customer != null) {
      setValues(customer);
    }
  }, [customer]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="justify-between px-4">
        <div className="">
          <div className="pt-6 pb-5 flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div className="mt-2">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Customer Details
              </h3>
            </div>
            <div className="mt-2 flex-shrink-0">
              <button
                type="button"
                className={
                  "bg-indigo-600 hover:bg-indigo-700 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white items-center"
                }
              >
                Open stripe
              </button>
            </div>
          </div>

          {/* here */}
          <div className="grid grid-cols-2 gap-4 pt-6 pb-5">
            <div className="sm:col-span-1 max-w-md">
              <Input
                label="First name"
                name="firstname"
                onChange={handleInputChange}
                value={values.firstname}
                labelOn={true}
              />
            </div>
            <div className="sm:col-span-1 max-w-md">
              <Input
                label="Last name"
                name="lastname"
                onChange={handleInputChange}
                value={values.lastname}
                labelOn={true}
              />
            </div>
            <div className="sm:col-span-1 max-w-md">
              <Input
                label="Email"
                name="email"
                onChange={handleInputChange}
                value={values.email}
                labelOn={true}
              />
            </div>
            <div className="sm:col-span-1 max-w-md">
              <Input
                label="Website"
                name="website"
                onChange={handleInputChange}
                value={values.website}
                labelOn={true}
              />
            </div>
            <div className="sm:col-span-1 max-w-md">
              <Input
                label="Account type"
                name="accountType"
                onChange={handleInputChange}
                value={values.userTypeText}
                labelOn={true}
                disabled={true}
              />
            </div>
            <div className="sm:col-span-1 max-w-md">
              <label
                htmlFor="userType"
                className="block text-sm font-medium text-gray-900"
              >
                Status
              </label>
              <select
                id="status"
                name="status"
                value={values.status}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              >
                <option value={0}>Needs verification</option>
                <option value={1}>Deleted</option>
                <option value={2}>Verified</option>
              </select>
            </div>
            <div className="sm:col-span-1 max-w-md">
              <label
                htmlFor="currency"
                className="block text-sm font-medium text-gray-900"
              >
                Currency
              </label>
              <select
                id="currency"
                name="currency"
                value={values.currency}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              >
                <option value={0}>---</option>
                <option value={1}>CAD</option>
                <option value={2}>USD</option>
              </select>
            </div>
            <div className="sm:col-span-1 max-w-md">
              <Input
                label="Timezone"
                name="timezone"
                value={values.timezone}
                labelOn={true}
                disabled={true}
              />
            </div>
            <div className="sm:col-span-1 max-w-md">
              <Input
                label="Date joined"
                name="addedDate"
                value={formatDate(values.addedDate)}
                labelOn={true}
                disabled={true}
              />
            </div>

            <div className="sm:col-span-2 max-w-md">
              <div className="mt-2">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Margin/markup
                </h3>
              </div>
            </div>

            {/* global settings starts */}
            <div className="sm:col-span-2 grid grid-cols-6 p-2 mt-2">
              <div className="sm:col-span-1 mt-2">Minimum: </div>
              <div className="sm:col-span-1 ml-0.5 mt-2">
                <select
                  id="respectMinPlusOrMinus"
                  name="respectMinPlusOrMinus"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  value={values.respectMinPlusOrMinus}
                  onChange={handleInputChange}
                >
                  <option value={0}>Cost +</option>
                </select>
              </div>
              <div className="sm:col-span-1 ml-0.5 mt-2">
                <Input
                  label="Respect Minimum amount"
                  name="respectMinAmount"
                  onChange={handleInputChange}
                  value={values.respectMinAmount}
                  type="number"
                  step="0.01"
                  min="0"
                />
              </div>
              <div className="sm:col-span-1 ml-0.5 mt-2">
                <select
                  id="respectMinCurrencyOrPercentage"
                  name="respectMinCurrencyOrPercentage"
                  value={values.respectMinCurrencyOrPercentage}
                  onChange={handleInputChange}
                  className={classNames(
                    values.respectMinPlusOrMinus === "1"
                      ? "hidden"
                      : "mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  )}
                >
                  <option value={0}>{values.abbreviation}</option>
                  <option value={1}>%</option>
                </select>
              </div>
              <div className="sm:col-span-2"></div>
              <div className="sm:col-span-1 mt-3">Maximum: </div>
              <div className="sm:col-span-1 ml-0.5 mt-3">
                <select
                  id="respectMaxPlusOrMinus"
                  name="respectMaxPlusOrMinus"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  value={values.respectMaxPlusOrMinus}
                  onChange={handleInputChange}
                >
                  <option value={0}>Cost +</option>
                  <option value={2}>Not set</option>
                </select>
              </div>
              <div
                className={classNames(
                  values.respectMaxPlusOrMinus === "1" ||
                    values.respectMaxPlusOrMinus === "2"
                    ? "hidden"
                    : "sm:col-span-1 ml-0.5 mt-3"
                )}
              >
                <Input
                  label="Respect Maximum amount"
                  name="respectMaxAmount"
                  onChange={handleInputChange}
                  value={values.respectMaxAmount}
                  type="number"
                  step="0.01"
                  min="0"
                />
              </div>
              <div
                className={classNames(
                  values.respectMaxPlusOrMinus === "1" ||
                    values.respectMaxPlusOrMinus === "2"
                    ? "hidden"
                    : "sm:col-span-1 ml-0.5 mt-3"
                )}
              >
                <select
                  id="respectMaxCurrencyOrPercentage"
                  name="respectMaxCurrencyOrPercentage"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  value={values.respectMaxCurrencyOrPercentage}
                  onChange={handleInputChange}
                >
                  <option value={0}>{values.abbreviation}</option>
                  <option value={1}>%</option>
                </select>
              </div>
              <div className="sm:col-span-2"></div>
            </div>
            {/* global settings ends */}

            <div className="sm:col-span-1 max-w-md">
              <label>Always end decimal prices with</label>

              <select
                id="preferenceEndWith"
                name="preferenceEndWith"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                value={values.preferenceEndWith}
                onChange={handleInputChange}
              >
                <option value={0}>00</option>
                <option value={1}>49</option>
                <option value={2}>50</option>
                <option value={3}>90</option>
                <option value={4}>95</option>
                <option value={5}>99</option>
              </select>
            </div>

            {submitError ? (
              <p className="text-md text-red-500">{submitError}</p>
            ) : null}
            <div className="sm:col-span-2 sm:flex-no-wrap">
              <div className="my-auto flex items-center">
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 items-center"
                  disabled={disableSubmitButton}
                >
                  {disableSubmitButton ? (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
