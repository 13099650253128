import { Fragment, useEffect, useRef } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { ChevronDownIcon } from "@heroicons/react/24/solid";

import { Menu, Transition } from "@headlessui/react";

import ImportProductsModal from "../../../../components/ModalTemplates/ImportProductsModal";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const tabs = [
  { name: "Active Products", href: "#" },
  { name: "Archived Products", href: "#" },
];

export const CustomerCompetitorsHeaderArea = (props) => {
  const {
    customerId,
    handleSearch,
    loading,
    openActionModal,
    setShow,
    setOpenActionModal,
    totalCompetitors,
    userId,
  } = props;
  const searchRef = useRef();

  const addProduct = () => {};

  return (
    <>
      <div className="px-4 py-6 sm:px-0">
        <div className="bg-white px-4 py-5 sm:px-12">
          <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div className="ml-4 mt-2">
              {/* search starts */}
              <div className="mt-1 flex rounded-md shadow-sm">
                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                  <input
                    type="text"
                    name="searchCompetitor"
                    id="searchCompetitor"
                    className=" block w-full rounded-lg rounded-l-md pl-2 sm:text-md border border-gray-300 rounded-tr-none rounded-br-none"
                    placeholder="Search"
                    onChange={handleSearch}
                  />
                </div>
                <button
                  type="button"
                  className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none"
                >
                  <MagnifyingGlassIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </button>
              </div>
              {/* search ends */}
            </div>
            <div className="ml-4 mt-2 flex-shrink-0"></div>
          </div>
        </div>
      </div>
    </>
  );
};
