import { useState } from "react";
import {
  deleteAssignedCompetitorApiCall,
  fetchAllBrandsApiCall,
  fetchAllCategoriesApiCall,
  fetchAllRulesApiCall,
  fetchProductByIdApiCall,
  fetchProductScrapesApiCall,
  getProductCompetitorsApiCall,
  scrapeSingleProductApiCall,
} from "../../domain/networkCalls/networkCalls";

import { updateObjectInArray } from "../../shared/utility";

export function ProductDetailsViewModel(checkRequestError, productId) {
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [competitors, setCompetitors] = useState([]);
  const [customerId, setCustomerId] = useState(0);
  const [emptyModal, setEmptyModal] = useState({
    open: false,
    childComponent: null,
  });
  const [errorModalOpen, setErrorModalOpen] = useState({
    open: false,
    title: null,
    subtitle: null,
  });
  const [loading, setLoading] = useState(true);
  const [openActionModal, setOpenActionModal] = useState({
    open: false,
    title: null,
    subtitle: null,
  });
  const [openSidePanel, setOpenSidePanel] = useState(false);
  const [product, setProduct] = useState("");
  const [rules, setRules] = useState([]);
  const [scrapes, setScrapes] = useState([]);
  const [sidePanelExtra, setSidePanelExtra] = useState({
    childComponent: null,
  });
  const [show, setShow] = useState({ show: false });
  const [submitError, setSubmitError] = useState(null);

  const fetchAllBrands = async function (customerId = customerId) {
    const callResult = await fetchAllBrandsApiCall(customerId);

    if (callResult.success) {
      setBrands(callResult.data.brands);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Oops",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
      }
    }
  };

  const fetchAllCategories = async function (customerId = customerId) {
    const callResult = await fetchAllCategoriesApiCall(customerId);

    if (callResult.success) {
      setCategories(callResult.data.categories);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Oops",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
      }
    }
  };

  async function fetchProductById() {
    const callResult = await fetchProductByIdApiCall(productId);

    if (callResult.success) {
      setCustomerId(callResult.data.product.ownerId);

      let brandsCall = await fetchAllBrands(callResult.data.product.ownerId);
      let catCall = await fetchAllCategories(callResult.data.product.ownerId);

      setProduct(callResult.data.product);
      setLoading(false);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Oops",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
      }
    }
  }

  async function getProductCompetitors(search) {
    const callResult = await getProductCompetitorsApiCall(
      productId,
      customerId,
      1,
      search
    );

    if (callResult.success) {
      setCompetitors(callResult.data.competitors);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Oops",
          subtitle: "We could not fetch the competitors, please try again.",
        });
      }
    }
  }

  const fetchAllRules = async function () {
    let callResult = await fetchAllRulesApiCall(customerId);

    if (callResult.success) {
      setRules(callResult.data.pricingRules);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
      }
    }
  };

  function updateProductInState(product, message) {
    setProduct(product);
    setShow({ show: true, title: message });
  }

  function addNewCompetitorToProduct() {
    getProductCompetitors();
    setOpenSidePanel(false);
  }

  function updateSingleProductCompetitor(
    rowId,
    productId,
    competitor,
    message
  ) {
    const currentCompetitors = [...competitors];
    const compIndex = competitors.findIndex((comp) => comp.urlId === rowId);
    console.log("comp-rowId", rowId);
    console.log("compIndex", compIndex);
    if (compIndex >= 0) {
      const payload = {
        index: compIndex,
        item: competitor,
      };
      const updatedCompetitors = updateObjectInArray(
        currentCompetitors,
        payload
      );

      setCompetitors(updatedCompetitors);
      setShow({ show: true, title: message });
    } else {
      setShow({ show: true, title: "Competitor update failed." });
    }

    setOpenSidePanel(false);
    setSidePanelExtra({ childComponent: null });
  }

  const deleteAssignedCompetitor = async function (rowId, urlId) {
    let callResult = await deleteAssignedCompetitorApiCall(
      customerId,
      productId
    );

    if (callResult.success) {
      console.log("deleteing");
      console.log("urlId", urlId);
      const updatedCompetitors = competitors.filter(
        (competitor) => competitor.urlId !== urlId
      );
      console.log("updatedCompetitors", updatedCompetitors);

      setCompetitors(updatedCompetitors);
      setOpenActionModal({
        open: false,
        title: null,
        subtitle: null,
      });
      setShow({ show: true, title: "Competitor deleted." });
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
      }
    }
  };

  const fetchProductScrapes = async function () {
    let callResult = await fetchProductScrapesApiCall(customerId, productId);

    if (callResult.success) {
      setScrapes(callResult.data.scrapes);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle: "We could not fetch the audit log, please try again.",
        });
      }
    }
  };

  const scrapeSingleProduct = async function (ignoreProdId, isUpdateApi) {
    let callResult = await scrapeSingleProductApiCall(
      customerId,
      productId,
      isUpdateApi
    );

    if (callResult.success) {
      setEmptyModal({
        childComponent: null,
        open: false,
      });
      setShow({ show: true, title: callResult.data.result });
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle: "We could not queue this product, please try again.",
        });
      }
    }
  };

  return {
    addNewCompetitorToProduct,
    brands,
    categories,
    competitors,
    currentTab,
    customerId,
    deleteAssignedCompetitor,
    emptyModal,
    errorModalOpen,
    getProductCompetitors,
    fetchAllBrands,
    fetchAllCategories,
    fetchAllRules,
    fetchProductById,
    fetchProductScrapes,
    loading,
    openActionModal,
    openSidePanel,
    product,
    rules,
    scrapes,
    scrapeSingleProduct,
    setCurrentTab,
    setEmptyModal,
    setErrorModalOpen,
    setLoading,
    setOpenActionModal,
    setOpenSidePanel,
    setSidePanelExtra,
    setShow,
    setSubmitError,
    sidePanelExtra,
    show,
    submitError,
    updateProductInState,
    updateSingleProductCompetitor,
  };
}
