import React, { useEffect, useState } from "react";

import { CustomerProductsViewModel } from "../../../../viewmodels/customers/CustomerProductsViewModel";
import { ProductsHeaderArea } from "./ProductsHeaderArea";
import { ProductsTable } from "./ProductsTable";

export const CustomerProducts = (props) => {
  const {
    checkRequestError,
    customerId,
    currency,
    openActionModal,
    setEmptyModal,
    setErrorModalOpen,
    setOpenActionModal,
    setShow,
    setSidePanelExtra,
    setOpenSidePanel,
    userId,
  } = props;

  const {
    addNewCompetitorToProduct,
    archivedProductSuccessfully,
    brands,
    categories,
    changeProductTabs,
    currentTab,
    deleteProductCompetitorFromState,
    fetchAllBrands,
    fetchAllCategories,
    fetchAllRules,
    getCustomerProducts,
    loading,
    page,
    products,
    queueAuditForProduct,
    refreshProductsList,
    rules,
    setNewCompetitorsForProduct,
    updateProduct,
    updateSingleProductCompetitor,
  } = CustomerProductsViewModel(
    checkRequestError,
    customerId,
    setErrorModalOpen,
    setShow,
    setOpenSidePanel,
    setSidePanelExtra
  );

  useEffect(() => {
    getCustomerProducts(page, "");
    fetchAllBrands();
    fetchAllCategories();
    fetchAllRules();
  }, []);

  const handleSearch = (e) => {
    const term = e.target.value;
    if (term.length >= 3) {
      getCustomerProducts(page, term);
    }
  };

  return (
    <div className="mx-auto py-6 sm:px-6 lg:px-8">
      <ProductsHeaderArea
        brands={brands}
        categories={categories}
        changeProductTabs={changeProductTabs}
        checkRequestError={checkRequestError}
        currency={currency}
        currentTab={currentTab}
        customerId={customerId}
        fetchAllBrands={fetchAllBrands}
        fetchAllCategorie={fetchAllCategories}
        refreshProductsList={refreshProductsList}
        handleSearch={handleSearch}
        loading={loading}
        openActionModal={openActionModal}
        setOpenSidePanel={setOpenSidePanel}
        setShow={setShow}
        setSidePanelExtra={setSidePanelExtra}
        setOpenActionModal={setOpenActionModal}
        totalProducts={products.total}
        userId={userId}
      />
      <ProductsTable
        addNewCompetitorToProduct={addNewCompetitorToProduct}
        archivedProductSuccessfully={archivedProductSuccessfully}
        brands={brands}
        categories={categories}
        checkRequestError={checkRequestError}
        currency={currency}
        customerId={customerId}
        deleteProductCompetitorFromState={deleteProductCompetitorFromState}
        fetchAllBrands={fetchAllBrands}
        fetchAllCategories={fetchAllCategories}
        fetchAllRules={fetchAllRules}
        loading={loading}
        openActionModal={openActionModal}
        products={products}
        queueAuditForProduct={queueAuditForProduct}
        refreshProductsList={refreshProductsList}
        rules={rules}
        setEmptyModal={setEmptyModal}
        setErrorModalOpen={setErrorModalOpen}
        setNewCompetitorsForProduct={setNewCompetitorsForProduct}
        setOpenActionModal={setOpenActionModal}
        setOpenSidePanel={setOpenSidePanel}
        setShow={setShow}
        setSidePanelExtra={setSidePanelExtra}
        updateProduct={updateProduct}
        updateSingleProductCompetitor={updateSingleProductCompetitor}
      />
    </div>
  );
};
