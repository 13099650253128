import { useState } from "react";
import {
  fetchAllBrandsApiCall,
  fetchAllCategoriesApiCall,
  fetchAllRulesApiCall,
  getCustomerProductsApiCall,
  queueAuditForProductApiCall,
} from "../../domain/networkCalls/networkCalls";

import { updateObjectInArray } from "../../shared/utility";

export function CustomerProductsViewModel(
  checkRequestError,
  customerId,
  setErrorModalOpen,
  setShow,
  setOpenSidePanel,
  setSidePanelExtra
) {
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [products, setProducts] = useState({ productList: [], total: 0 });
  const [rules, setRules] = useState([]);

  const fetchAllBrands = async function () {
    const callResult = await fetchAllBrandsApiCall(customerId);

    if (callResult.success) {
      setBrands(callResult.data.brands);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Oops",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
      }
    }
  };

  const fetchAllCategories = async function () {
    const callResult = await fetchAllCategoriesApiCall(customerId);

    if (callResult.success) {
      setCategories(callResult.data.categories);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Oops",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
      }
    }
  };

  const fetchAllRules = async function () {
    let callResult = await fetchAllRulesApiCall(customerId);

    if (callResult.success) {
      setRules(callResult.data.pricingRules);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
      }
    }
  };

  async function getCustomerProducts(page, search) {
    const callResult = await getCustomerProductsApiCall(
      customerId,
      page,
      search,
      currentTab === 1 ? 3 : 0
    );

    if (callResult.success) {
      console.log("callresult:", callResult.data);
      setProducts({
        productList: callResult.data.products,
        total: callResult.data.totalProductsForTab,
      });
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Oops",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
      }
    }
  }

  function changeProductTabs(index) {
    setProducts({ productList: [], total: 0 });
    setLoading(true);
    setCurrentTab(index);
    getCustomerProducts(index);
  }

  const addNewCompetitorToProduct = (productId, newCompetitor, message) => {
    console.log("products", products);
    const currentProducts = [...products.productList];
    const prodIndex = products.productList.findIndex(
      (prod) => prod.productId === productId
    );

    if (prodIndex >= 0) {
      const newComp = {
        competitors: [
          ...products.productList[prodIndex].competitors,
          newCompetitor,
        ],
      };
      const payload = {
        index: prodIndex,
        item: newComp,
      };
      const updatedProducts = updateObjectInArray(currentProducts, payload);

      setProducts({ productList: updatedProducts, total: products.total });
      setOpenSidePanel(false);
      setShow({ show: true, title: message });
    } else {
      setShow({ show: true, title: "We had trouble adding competitor " });
    }
  };

  const archivedProductSuccessfully = (productId, message) => {
    setOpenSidePanel(false);
    const updatedProducts = products.productList.filter(
      (product) => product.productId !== productId
    );
    setProducts({ productList: updatedProducts, total: products.total - 1 });
    setShow({ show: true, title: message });
  };

  async function queueAuditForProduct(productId, isUpdateApi, customerId) {
    const callResult = await queueAuditForProductApiCall(
      productId,
      isUpdateApi,
      customerId
    );

    if (callResult.success) {
      setShow({ show: true, title: callResult.data.result });
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Oops",
          subtitle: "We could not queue the product, please try again.",
        });
      }
    }
  }

  const refreshProductsList = () => {
    setProducts({ productList: [], total: 0 });

    getCustomerProducts(1, "");
    fetchAllBrands();
    fetchAllCategories();
    fetchAllRules();
  };

  const updateProduct = (product) => {
    const currentProducts = [...products.productList];

    const prodIndex = products.productList.findIndex(
      (prod) => prod.productId === product.productId
    );

    const payload = {
      index: prodIndex,
      item: product,
    };
    const updatedProducts = updateObjectInArray(currentProducts, payload);

    setProducts({ productList: updatedProducts, total: products.total });
  };

  function setNewCompetitorsForProduct(productId, competitors) {
    const currentProducts = [...products.productList];
    const prodIndex = products.productList.findIndex(
      (prod) => prod.productId === productId
    );

    const newComp = {
      competitors: competitors,
    };
    const payload = {
      index: prodIndex,
      item: newComp,
    };
    const updatedProducts = updateObjectInArray(currentProducts, payload);

    setProducts({ productList: updatedProducts, total: products.total });
  }

  function updateSingleProductCompetitor(
    rowId,
    productId,
    competitor,
    message
  ) {
    const currentProducts = [...products.productList];
    const prodIndex = products.productList.findIndex(
      (prod) => prod.productId === productId
    );

    if (prodIndex >= 0) {
      const currentCompetitors = [
        ...products.productList[prodIndex].competitors,
      ];
      const compIndex = products.productList[prodIndex].competitors.findIndex(
        (comp) => comp.urlId === rowId
      );

      const payload = {
        index: compIndex,
        item: competitor,
      };
      const updatedCompetitors = updateObjectInArray(
        currentCompetitors,
        payload
      );

      if (compIndex >= 0) {
        const prodPayload = {
          index: prodIndex,
          item: { competitors: updatedCompetitors },
        };

        const updatedProducts = updateObjectInArray(
          currentProducts,
          prodPayload
        );

        setProducts({ productList: updatedProducts, total: products.total });
        setShow({ show: true, title: message });
      } else {
        setShow({ show: true, title: "Competitor update failed." });
      }
    } else {
      setShow({ show: true, title: "Competitor update failed." });
    }
    setOpenSidePanel(false);
    setSidePanelExtra({ childComponent: null });
  }

  function deleteProductCompetitorFromState(rowId, urlId) {
    const productId = products.productList[rowId].productId;
    const tempCompetitors = products.productList[rowId].competitors;

    const updatedCompetitors = tempCompetitors.filter(
      (competitor) => competitor.urlId !== urlId
    );

    setNewCompetitorsForProduct(productId, updatedCompetitors);
    setShow({ show: true, title: "Competitor deleted." });
  }

  return {
    addNewCompetitorToProduct,
    archivedProductSuccessfully,
    brands,
    categories,
    changeProductTabs,
    currentTab,
    deleteProductCompetitorFromState,
    fetchAllBrands,
    fetchAllCategories,
    fetchAllRules,
    getCustomerProducts,
    loading,
    page,
    products,
    queueAuditForProduct,
    refreshProductsList,
    rules,
    setNewCompetitorsForProduct,
    updateProduct,
    updateSingleProductCompetitor,
  };
}
