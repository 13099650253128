import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";

import { ChevronDownIcon } from "@heroicons/react/24/solid";

import AssignCompetitorForm from "../../../../../components/forms/product/AssignCompetitorForm";
import { parentDomain } from "../../../../../constants";
import AssignPricingRuleToCompetitorForm from "../../../../../components/forms/product/AssignPricingRuleToCompetitorForm";
import { deleteAssignedCompetitorAtCustomerLevelApiCall } from "../../../../../domain/networkCalls/networkCalls";

import DeleteProductTemplate from "../../../../../components/ModalTemplates/DeleteProductTemplate";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const CustomerCompetitorsQuickActions = (props) => {
  const {
    checkRequestError,
    competitor,
    currency,
    customerId,
    deleteProductCompetitorFromTable,
    fetchAllRules,
    openActionModal,
    rules,
    setErrorModalOpen,
    setOpenActionModal,
    setOpenSidePanel,
    setSidePanelExtra,
    updateCompetitorInParentDomains,
  } = props;

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const deleteAssignedCompetitor = async function (competitorId) {
    setDisableSubmitButton(true);
    setOpenActionModal({
      ...openActionModal,
      buttonDisabled: true,
      itemId: 0,
    });
    const callResult = await deleteAssignedCompetitorAtCustomerLevelApiCall(
      customerId,
      competitorId
    );

    setOpenActionModal({
      buttonDisabled: false,
      open: false,
      setOpen: setOpenActionModal,
    });

    if (callResult.success) {
      deleteProductCompetitorFromTable(competitorId);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle:
            "We could not sync with your Shopify account, please try again.",
        });
      }
      setErrorModalOpen({
        open: true,
        title: "Sorry!",
        subtitle: "We could not delete the competitor, please try again.",
      });
      setDisableSubmitButton(false);
    }
  };

  return (
    <>
      <div className="ml-2 h-7 flex items-center">
        <span className="relative inline-flex shadow-sm rounded ">
          <Menu as="span" className="-ml-px relative block">
            <Menu.Button className="relative inline-flex items-center px-2 py-2 rounded border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none">
              <span>Edit</span>
              <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="cursor-pointer origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-900">
                <div className="py-1 z-900 text-left">
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm "
                        )}
                        onClick={() => {
                          setSidePanelExtra({
                            childComponent: (
                              <AssignCompetitorForm
                                checkRequestError={checkRequestError}
                                customerId={customerId}
                                isEdit={true}
                                competitor={competitor}
                                setShow={setOpenSidePanel}
                                setOpenSidePanel={setOpenSidePanel}
                                setOpen={setOpenSidePanel}
                                source={parentDomain}
                                subtitle="Fill in the information below to edit competitor."
                                title={"Editing " + competitor.parentDomain}
                                updateSingleProductCompetitor={
                                  updateCompetitorInParentDomains
                                }
                              />
                            ),
                          });
                          setOpenSidePanel(true);
                        }}
                      >
                        Edit competitor
                      </a>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm "
                        )}
                        onClick={() => {
                          setSidePanelExtra({
                            childComponent: (
                              <AssignPricingRuleToCompetitorForm
                                checkRequestError={checkRequestError}
                                currency={currency}
                                fetchAllRules={fetchAllRules}
                                source={parentDomain}
                                isEdit={competitor.ruleId > 0}
                                competitor={{
                                  itemType: 0,
                                  itemId: competitor.competitorId,
                                  ruleId: competitor.ruleId,
                                  explanation: competitor.explanation,
                                  ruleAssignId: competitor.ruleAssignId,
                                  assignLevel: 0,
                                  assignItemId: competitor.competitorId,
                                  ruleArray: competitor.ruleArray,
                                  competitorId: competitor.competitorId,
                                }}
                                rules={rules}
                                setOpen={setOpenSidePanel}
                                subtitle="Pricing rules affect the dynamic price of a product on a competitor level."
                                title={
                                  "Assign a pricing rule to " +
                                  competitor.parentDomain +
                                  "."
                                }
                                updateSingleProductCompetitor={
                                  updateCompetitorInParentDomains
                                }
                                customerId={customerId}
                              />
                            ),
                            panelSize: "2xl",
                          });
                          setOpenSidePanel(true);
                        }}
                      >
                        {competitor.ruleId > 0 ? "Edit rule" : "Add rule"}
                      </a>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm "
                        )}
                        onClick={() => {
                          setOpenActionModal({
                            open: true,
                            setOpen: setOpenActionModal,
                            childComponent: (
                              <DeleteProductTemplate
                                actionFunc={deleteAssignedCompetitor}
                                buttonDisabled={false}
                                buttonMessage={"Delete"}
                                customerId={customerId}
                                itemId={competitor.competitorId}
                                message={
                                  "Type delete below to delete " +
                                  competitor.parentDomain +
                                  ". This action cannot be undone."
                                }
                                setOpen={setOpenActionModal}
                              />
                            ),
                          });
                        }}
                      >
                        Delete Competitor
                      </a>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </span>
      </div>
    </>
  );
};
